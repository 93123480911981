import React, { isValidElement, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { usePopper } from 'react-popper';
import uuidv4 from '../../utils/uuidv4';
const DROPDOWN_CONTAINER_ID = uuidv4('bron-dropdown-');
/**
 * A CSS selector list to query only focusable elements.
 * @see https://html.spec.whatwg.org/multipage/interaction.html#focusable
 * @type {string}
 */
export const focusableSelectors = 'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])';
/**
 * Bronson DropdownContainer component.
 *
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-dropdown-container.html
 *
 * @constructor
 */
export function DropdownContainer({ children, className, defaultContainerOffset, direction = 'bottom', flip = true, onToggle, open = false, showArrow = true, trigger, testId, ...otherProps }) {
    const [visible, setVisible] = useState(open);
    const [containerOffset, setContainerOffset] = useState(null);
    const arrowRef = useRef(null);
    const referenceRef = useRef(null);
    const popperRef = useRef(null);
    const classNameList = classNames('c-dropdown-container', className).trim();
    if (!trigger) {
        throw new TypeError('DropdownContainer must specify a valid `trigger`, e.g. `<Button>Open</Button>`');
    }
    useEffect(() => {
        /**
         * Cleanup function to prevent the internal state of the component
         * from being updated when it is unmounted.
         */
        const timer = setTimeout(() => {
            if (referenceRef?.current) {
                setContainerOffset(parseInt(getComputedStyle(referenceRef?.current).getPropertyValue('--js-dropdown-container-offset'), 10));
            }
        }, 0);
        return () => clearTimeout(timer);
    }, [containerOffset]);
    const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, {
        placement: `${direction}-start`,
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    boundary: document.body,
                },
            },
            {
                name: 'offset',
                options: {
                    /**
                     * Fine-tuning the position here.
                     * @see https://popper.js.org/docs/v2/modifiers/offset/
                     */
                    offset: [0, defaultContainerOffset ?? containerOffset],
                },
            },
            ...(showArrow
                ? [
                    {
                        name: 'arrow',
                        options: {
                            element: arrowRef?.current,
                        },
                    },
                ]
                : []),
            {
                name: 'flip',
                options: {
                    /**
                     * This disables the flip behavior.
                     * @see https://popper.js.org/docs/v2/modifiers/flip/#fallbackplacements
                     */
                    ...(!flip && { fallbackPlacements: [direction] }),
                },
            },
        ],
    });
    const containerStyle = {
        ...styles.popper,
    };
    /**
     * Handle clicks outside the DropdownContainer.
     */
    useEffect(() => {
        const outsideClickHandler = (event) => {
            const triggerButton = referenceRef?.current?.querySelector('button');
            const eventTarget = event?.target;
            /**
             * Only close the current dropdown on an outside click.
             */
            if (visible && !eventTarget.closest('.c-dropdown-container__panel') && !triggerButton?.contains(eventTarget)) {
                setVisible(false);
                onToggle?.(false);
            }
        };
        document.addEventListener('mousedown', outsideClickHandler, {
            capture: true,
        });
        /**
         * Cleanup the hook.
         */
        return () => document.removeEventListener('mousedown', outsideClickHandler, {
            capture: true,
        });
    }, [onToggle, visible]);
    /**
     * Handle `Esc` keypress.
     */
    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                const firstFocusable = referenceRef?.current?.querySelector(focusableSelectors);
                setVisible(false);
                onToggle?.(false);
                firstFocusable?.focus();
            }
        };
        document.addEventListener('keydown', handleEscape, {
            capture: true,
        });
        return () => document.removeEventListener('keydown', handleEscape, {
            capture: true,
        });
    }, [onToggle, visible]);
    /**
     * Keep the focus inside.
     */
    useEffect(() => {
        const focusableEls = Array.from(popperRef?.current?.querySelectorAll(focusableSelectors) ?? []);
        const firstFocusableEl = focusableEls?.[0];
        const lastFocusableEl = focusableEls?.[focusableEls?.length - 1];
        const KEYCODE_TAB = 9;
        const instance = popperRef?.current;
        const handleFocusTrap = (e) => {
            const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;
            if (!isTabPressed) {
                return;
            }
            if (e.shiftKey) {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl?.focus();
                    e.preventDefault();
                }
            }
            else if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        };
        instance?.addEventListener('keydown', handleFocusTrap, { capture: true });
        return () => instance?.removeEventListener('keydown', handleFocusTrap, { capture: true });
    });
    function renderIfArrow() {
        if (showArrow) {
            return React.createElement("span", { ref: arrowRef, className: "c-dropdown-container__arrow", "aria-hidden": "true", style: styles.arrow });
        }
        return null;
    }
    function renderIfTrigger() {
        if (isValidElement(trigger)) {
            return React.cloneElement(trigger, {
                onClick: () => {
                    setVisible(!visible);
                    onToggle?.(!visible);
                },
                'aria-expanded': visible,
                'aria-controls': DROPDOWN_CONTAINER_ID,
                className: classNames(trigger.props.className, 'c-dropdown-container__button'),
                iconClassName: classNames(trigger.props.className, 'c-dropdown-container__icon'),
                type: 'button',
            });
        }
        return null;
    }
    return (React.createElement("div", { className: classNameList, "data-test-id": testId, ...otherProps },
        React.createElement("div", { className: "c-dropdown-container__trigger-wrapper", ref: referenceRef }, renderIfTrigger()),
        React.createElement("div", { id: DROPDOWN_CONTAINER_ID, ref: popperRef, className: "c-dropdown-container__panel", style: containerStyle, "aria-hidden": !visible, ...attributes.popper },
            renderIfArrow(),
            children)));
}
export function DropdownItem({ className, children }) {
    const classNameList = classNames('c-dropdown-container__item', className).trim();
    return React.createElement("div", { className: classNameList }, children);
}
DropdownItem.displayName = 'DropdownContainer.Item';
DropdownContainer.Item = DropdownItem;
export function DropdownDivider({ className }) {
    const classNameList = classNames('c-dropdown-container__divider', className).trim();
    return React.createElement("div", { className: classNameList, "aria-hidden": "true" });
}
DropdownDivider.displayName = 'DropdownContainer.Divider';
DropdownContainer.Divider = DropdownDivider;
