import React from 'react';
import classNames from 'classnames';
import { PageWrap } from '../PageWrap/PageWrap';
/**
 * Bronson ContentSection component.
 * @see https://bronson.vwfs.tools/default/docs/objects/content-section.html
 * @constructor
 */
export function ContentSection({ altBackground, children, className, pageWrap, pageWrapSize, testId, }) {
    function renderContent() {
        if (pageWrap || pageWrapSize) {
            return React.createElement(PageWrap, { size: pageWrapSize }, children);
        }
        return children;
    }
    const classNameList = classNames('o-content-section', className).trim();
    return (React.createElement("section", { className: classNameList, "data-theme": altBackground ? 'alternative' : undefined, "data-testid": testId }, renderContent()));
}
/**
 * Bronson ComponentWrapper component (nested).
 * @constructor
 */
function ComponentWrapper({ children, className }) {
    const classNameList = classNames('o-component-wrapper', className).trim();
    return React.createElement("div", { className: classNameList }, children);
}
ContentSection.ComponentWrapper = ComponentWrapper;
