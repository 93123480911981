import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { PageWrap } from '../../Objects/PageWrap/PageWrap';
import { ReactLink } from '../ReactLink/ReactLink';
import { Paragraph } from '../Paragraph/Paragraph';
import uuidv4 from '../../utils/uuidv4';
import { Icon } from '../Icon/Icon';
/**
 * Bronson Footer component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-footer--footer-full.html
 * @constructor
 */
export function Footer({ children, testId, ...otherProps }) {
    return (React.createElement("footer", { className: "c-footer", "data-testid": testId, ...otherProps }, children));
}
Footer.displayName = 'Footer';
/**
 * FooterModuleBreadcrumbs
 * @constructor
 */
export function FooterModuleBreadcrumbs({ children, testId, ...otherProps }) {
    return (React.createElement("div", { className: "c-footer__module c-footer__module--breadcrumb", "data-testid": testId, ...otherProps },
        React.createElement(PageWrap, null,
            React.createElement("div", { className: "c-footer-breadcrumb" }, children))));
}
FooterModuleBreadcrumbs.displayName = 'Footer.FECK';
Footer.ModuleBreadcrumbs = FooterModuleBreadcrumbs;
/**
 * FooterModuleNavColumns
 * @constructor
 */
export function FooterModuleNavColumns({ children, testId, ...otherProps }) {
    return (React.createElement("div", { className: "c-footer__module c-footer__module--nav", "data-testid": testId, ...otherProps },
        React.createElement(PageWrap, null,
            React.createElement("nav", { className: "c-footer-nav" },
                React.createElement("div", { className: "c-footer-nav__columns" }, children)))));
}
FooterModuleNavColumns.displayName = 'Footer.ModuleNavColumns';
Footer.ModuleNavColumns = FooterModuleNavColumns;
/**
 * Bronson FooterNavColumn component (nested).
 * @constructor
 */
export function FooterNavColumn({ children, title, testId }) {
    /**
     * Add state handling for footer accordion.
     */
    const [active, setActive] = useState(false);
    /**
     * Allocate unique identifiers for accessibility reasons.
     * @type {string|*}
     */
    const buttonUUID = useMemo(() => uuidv4('bron-footer-button-'), []);
    const panelUUID = useMemo(() => uuidv4('bron-footer-panel-'), []);
    const columnClassName = classNames('c-footer-nav__column', 'js-footer-accordion', // Used in Bronson CSS.
    'js-is-ready' // Used in Bronson CSS.
    );
    const titleClassName = classNames('c-footer-nav__title', {
        'is-active': active,
    });
    const panelClassName = classNames('c-footer-nav__list', 'js-is-ready', // Used in Bronson CSS.
    {
        'is-closed': !active,
    });
    return (React.createElement("div", { className: columnClassName, "data-testid": testId },
        React.createElement("div", { className: titleClassName, onClick: () => setActive(!active) },
            React.createElement("h4", { className: "c-footer-nav__title-label is-heading" }, title),
            React.createElement("button", { className: "c-footer-nav__title-label is-button", type: "button", "aria-expanded": active, "aria-controls": panelUUID, id: buttonUUID }, title)),
        React.createElement("ul", { id: panelUUID, "aria-labelledby": buttonUUID, role: "region", className: panelClassName }, children)));
}
FooterNavColumn.displayName = 'Footer.NavColumn';
Footer.NavColumn = FooterNavColumn;
/**
 * Bronson FooterNavColumnItem component (nested).
 * @constructor
 */
export function FooterNavColumnItem({ children, href, linkProps, normalLink, onClick, testId, ...otherProps }) {
    return (React.createElement("li", { className: "c-footer-nav__item", "data-testid": testId, ...otherProps },
        React.createElement(ReactLink, { className: "c-footer-nav__link", onClick: onClick, href: href, normalLink: normalLink, ...linkProps }, children)));
}
FooterNavColumn.Item = FooterNavColumnItem;
Footer.NavColumn.Item = FooterNavColumn.Item;
/**
 * FooterModuleMetaNav
 * @constructor
 */
export function FooterModuleMetaNav({ children, copyrightSign = '©', copyrightText, socialItems, testId, ...otherProps }) {
    return (React.createElement("div", { className: "c-footer__module c-footer__module--meta-nav", "data-testid": testId, ...otherProps },
        React.createElement("div", { className: "o-page-wrap" },
            React.createElement("nav", { className: "c-footer-meta-bar" },
                React.createElement("ul", { className: "c-footer-meta-nav" }, children),
                socialItems && (React.createElement("ul", { className: "c-footer-social-media" }, socialItems.map(({ href, icon, label, ...linkAttrs }, i) => {
                    const iconClassNames = classNames('c-footer-social-media__icon');
                    return (React.createElement("li", { className: "c-footer-social-media__item", key: i },
                        React.createElement(ReactLink, { href: href, title: label, className: "c-footer-social-media__link", ...linkAttrs },
                            React.createElement(Icon, { className: iconClassNames, name: icon }))));
                }))),
                React.createElement("span", { className: "c-footer-copyright" },
                    React.createElement("span", { className: "c-footer-copyright__sign" }, copyrightSign),
                    React.createElement("span", { className: "c-footer-copyright__text" }, copyrightText))))));
}
FooterModuleMetaNav.displayName = 'Footer.ModuleMetaNav';
Footer.ModuleMetaNav = FooterModuleMetaNav;
/**
 * Bronson FooterModuleMetaNavItem component (nested).
 * @constructor
 */
export function FooterModuleMetaNavItem({ children, href, linkProps, normalLink, onClick, testId, ...otherProps }) {
    return (React.createElement("li", { ...otherProps, className: "c-footer-meta-nav__item", "data-testid": testId },
        React.createElement(ReactLink, { className: "c-footer-meta-nav__link", onClick: onClick, href: href, normalLink: normalLink, ...linkProps }, children)));
}
FooterModuleMetaNavItem.displayName = 'Footer.ModuleMetaNav.Item';
FooterModuleMetaNav.Item = FooterModuleMetaNavItem;
Footer.ModuleMetaNav.Item = FooterModuleMetaNavItem;
/**
 * FooterModuleFootnotes
 * @constructor
 */
export function FooterModuleFootnotes({ children, footerText, testId, ...otherProps }) {
    function renderFooterText() {
        if (footerText) {
            /**
             * If {@link footerText} is a string, just return it as a single child.
             */
            if (typeof footerText === 'string') {
                return React.createElement(Paragraph, null, footerText);
            }
            return footerText;
        }
        return null;
    }
    return (React.createElement("div", { className: "c-footer__module c-footer__module--footnotes", "data-testid": testId, ...otherProps },
        React.createElement("div", { className: "o-page-wrap" },
            React.createElement("div", { className: "c-footer-footnotes" },
                renderFooterText(),
                children))));
}
FooterModuleFootnotes.displayName = 'Footer.ModuleFootnotes';
Footer.ModuleFootnotes = FooterModuleFootnotes;
