import React from 'react';
import classNames from 'classnames';
/**
 * Bronson RadioButton component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-radio-button.html
 * @constructor
 */
export function RadioButton({ children, className, error, id, name, testId, ...otherProps }) {
    const labelClassNameList = classNames('c-radio', className).trim();
    const inputClassNameList = classNames('c-radio__input', {
        /**
         * @DEPRECATED Remove in v11.
         */
        'is-error ': error,
    }).trim();
    return (React.createElement("label", { className: labelClassNameList, htmlFor: id },
        React.createElement("input", { id: id, className: inputClassNameList, type: "radio", name: `${name || 'radio-button'}`, "data-testid": testId, ...otherProps }),
        React.createElement("span", { className: "c-radio__label" }, children)));
}
