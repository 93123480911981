import React from 'react';
import classNames from 'classnames';
/**
 * Bronson PageWrap component.
 * @see https://bronson.vwfs.tools/default/docs/objects/wrapper.html
 * @constructor
 */
export function PageWrap({ children, size, ...otherProps }) {
    const pageWrapClassName = classNames('o-page-wrap', {
        [`o-page-wrap--${size}`]: size,
    }).trim();
    return (React.createElement("div", { ...otherProps, className: pageWrapClassName }, children));
}
