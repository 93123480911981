import { useEffect } from 'react';
/**
 * Asynchronously observe intersections of a target reference element.
 * Please note that IE11 and other legacy browser do not implement the IntersectionObserver API
 * and therefore need to polyfill the missing functionality.
 * @example
 *    const myTarget = useRef(null)
 *
 *    export function Example({}) {
 *      const myTarget = useRef(null);
 *      useIntersectionObserver({
 *        ref: sectionNavRef,
 *        target: myTarget,
 *        onIntersectionHandler: ScrollObserverCore.observeEntries,
 *        opts: {
 *          intersectionObserverOpts: { rootMargin: '0px 0px -100%' },
 *          classes: ScrollObserverCore.classes,
 *        },
 *      })
 *      return (
 *        <div ref={myTarget}>👋</div>
 *      );
 *    };
 */
function useIntersectionObserver({ ref, target, onIntersectionHandler = () => () => { }, opts, onMatch = () => { }, }) {
    useEffect(() => {
        const { current } = ref ?? {};
        const currentTarget = Array.isArray(target)
            ? target.filter((t) => t?.current)
            : target?.current;
        /**
         * If we have an {@link HTMLElement} to observe.
         */
        if (current && currentTarget) {
            const intersectionObserver = new IntersectionObserver((entries) => {
                if (onIntersectionHandler) {
                    onIntersectionHandler?.(current, opts, onMatch)(entries);
                }
            }, opts?.intersectionObserverOpts);
            /**
             * Handle a collection of targets.
             */
            if (Array.isArray(currentTarget)) {
                currentTarget.forEach((t) => {
                    if (t?.current) {
                        intersectionObserver.observe(t.current);
                    }
                });
            }
            else if (currentTarget) {
                intersectionObserver.observe(currentTarget);
            }
            return () => {
                if (Array.isArray(currentTarget)) {
                    return currentTarget.forEach((t) => {
                        if (t?.current) {
                            intersectionObserver.unobserve(t.current);
                        }
                    });
                }
                else if (currentTarget) {
                    return intersectionObserver.unobserve(currentTarget);
                }
            };
        }
        return () => { };
    }, [onIntersectionHandler, onMatch, opts, ref, target]);
}
export default useIntersectionObserver;
