import React, { useState, useEffect, isValidElement } from 'react';
import classNames from 'classnames';
import { BasicSlider, useBasicSlider } from '../BasicSlider/BasicSlider';
/**
 * Use the Carousel component to create a slideshow that a user can interact with.
 * This slideshow can contain a series of {@link CarouselImage}.
 *
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-carousel--carousel.html
 *
 * @constructor
 */
export function Carousel({ autoPlay = false, buttonsOnHover, buttonsVisible, children, className, counterEnforced, fullscreen = false, itemAspectRatio, lazyLoad = false, objectFitContain, wrapAround = false, testId, ...otherProps }) {
    const defaultAutoPlayInterval = 5000;
    const initialResizeThreshold = 300;
    const [flickityRef, setFlickityRef] = useState(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    useBasicSlider(flickityRef, children, { initialResizeThreshold });
    /**
     * Handles {@link isFullscreen} when entering or existing fullscreen mode.
     */
    useEffect(() => {
        if (flickityRef) {
            flickityRef.on?.('fullscreenChange', setIsFullscreen);
        }
    }, [flickityRef]);
    /**
     * Add `[data-slider-counter]` attribute when {@link BasicSlider} is mounted
     * and {@link counterEnforced} is set.
     * Add `[data-carousel-fullscreen]` attribute when {@link BasicSlider} is mounted
     * and {@link fullscreen} is set.
     */
    useEffect(() => {
        if (flickityRef && counterEnforced) {
            flickityRef.element?.setAttribute('data-slider-counter', `${counterEnforced}`);
        }
        if (flickityRef && fullscreen) {
            flickityRef.element?.setAttribute('data-carousel-fullscreen', `${fullscreen}`);
        }
    }, [flickityRef, counterEnforced, fullscreen]);
    /**
     * Add `[data-testid]` attribute when {@link BasicSlider} is mounted
     * and {@link testId} is set.
     */
    useEffect(() => {
        if (flickityRef && testId) {
            flickityRef.element?.setAttribute('data-testid', testId);
        }
    }, [flickityRef, testId]);
    const flickityOptions = {
        autoPlay: autoPlay === true ? defaultAutoPlayInterval : autoPlay,
        cellSelector: '.c-carousel__item',
        fullscreen,
        imagesLoaded: true,
        lazyLoad,
        wrapAround,
    };
    const classNameList = classNames('c-carousel', {
        'c-carousel--buttons-visible': buttonsVisible,
        'c-carousel--buttons-on-hover': buttonsOnHover,
    }, className).trim();
    return (React.createElement(React.Fragment, null,
        React.createElement(BasicSlider, { className: classNameList, flickityRef: (c) => setFlickityRef(c), options: flickityOptions, ...otherProps }, React.Children.map(children, (child) => {
            if (isValidElement(child)) {
                return React.cloneElement(child, {
                    /**
                     * Set {@link imgSizes} to `100vw` when entering fullscreen mode.
                     * @see https://jira.platform.vwfs.io/browse/BRON-6972
                     */
                    imgSizes: isFullscreen ? '100vw' : child?.props.imgSizes,
                    itemAspectRatio,
                    lazyLoad,
                    objectFitContain,
                });
            }
        }))));
}
/**
 * The CarouselImage component is used internally to wrap the images
 * and display them within the {@link Carousel}.
 *
 * Note: {@link itemAspectRatio}, {@link lazyLoad} and {@link objectFitContain}
 * are set by parent {@link Carousel}.
 *
 * @param {string} [altText] - The `[alt]` attribute on the image element.
 * @param {string} [className] - Additional CSS classes on the image element.
 * @param {string} [imgSizes] - The `[sizes]` attribute on the image element.
 * @param {string} [imgSrc] - The `[src]` attribute on the image element.
 * @param {string} [imgSrcSet] - The `[srcset]` attribute on the image element.
 * @param {Carousel.itemAspectRatio} [itemAspectRatio] - {@link Carousel.itemAspectRatio}.
 * @param {Carousel.lazyLoad} [lazyLoad] - {@link Carousel.lazyLoad}.
 * @param {Carousel.objectFitContain} [objectFitContain] - {@link Carousel.objectFitContain}.
 * @param {string} [testId] - The `[data-testid]` attribute on the component element.
 * @param {object} [otherProps] - Other props passed to the component element.
 * @return {JSX.Element} - The CarouselImage component.
 * @constructor
 */
function CarouselImage({ altText, className, imgSizes, imgSrc, imgSrcSet, itemAspectRatio, lazyLoad, objectFitContain, testId, ...otherProps }) {
    const classNameList = classNames('c-carousel__item', {
        'o-ratio': itemAspectRatio,
        [`o-ratio--${itemAspectRatio}`]: itemAspectRatio,
    }).trim();
    const imgClassNameList = classNames('c-carousel__image', {
        'u-object-fit-contain': objectFitContain,
        'o-ratio__content': itemAspectRatio,
    }, className).trim();
    /**
     * Handles the use of specific attributes for Flickity or
     * native HTML attributes depending on {@link lazyLoad}.
     */
    const imgProps = {
        [`${lazyLoad ? 'data-flickity-lazyload-src' : 'src'}`]: imgSrc,
        [`${lazyLoad ? 'data-flickity-lazyload-srcset' : 'srcSet'}`]: imgSrcSet,
    };
    return (React.createElement("div", { className: classNameList, "data-testid": testId, ...otherProps },
        React.createElement("img", { className: imgClassNameList, sizes: imgSizes, alt: altText, ...imgProps })));
}
CarouselImage.displayName = 'Carousel.Image';
Carousel.Image = CarouselImage;
