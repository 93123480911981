import React from 'react';
import classNames from 'classnames';
/**
 * The MediaGrid component is a grid of {@link MediaGridItem}
 * which are links around images displayed in a grid.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-media-grid.html
 * @constructor
 */
export function MediaGrid({ aspectRatio, className, children, testId, ...otherProps }) {
    const ulClassNameList = classNames('c-media-grid', className).trim();
    return (React.createElement("ul", { className: ulClassNameList, ...otherProps, "data-testid": testId, "data-aspect-ratio": aspectRatio ?? null }, children));
}
/**
 * The MediaGridItem component is internally used to wrap the item content
 * in a link and display it inside the {@link MediaGrid}.
 * @constructor
 */
function MediaGridItem({ children, href, label, ...otherProps }) {
    return (React.createElement("li", { className: "c-media-grid__item" },
        React.createElement("a", { className: "c-media-grid__link", href: href, ...otherProps },
            React.Children.map(children, (child) => React.cloneElement(child, { className: 'c-media-grid__image' })),
            label && React.createElement("span", { className: "c-media-grid__label" }, label))));
}
MediaGridItem.displayName = 'MediaGrid.Item';
MediaGrid.Item = MediaGridItem;
