import React from 'react';
import classNames from 'classnames';
import { Button } from '../Button/Button';
/**
 * Bronson MediaTeaser component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-media-teaser.html
 * @constructor
 */
export function MediaTeaser({ buttonLabel, buttonModifier, buttonProps, children, className, fullLink, fullLinkFooter, imgProps, linkButton, onButtonClick, pretitle, reversed, title, vertical, video, testId, ...otherProps }) {
    const sectionClassNameList = classNames('c-media-teaser', {
        // Main class modifier convenience prop extension.
        'c-media-teaser--reversed': reversed,
        'c-media-teaser--vertical': vertical,
        'c-media-teaser--full-link': fullLink, // Convenience prop from Bronson variants.
    }, className).trim();
    const btnClassNameList = classNames({
        'c-media-teaser__link': linkButton && !fullLink,
    }, buttonModifier).trim();
    const renderIfFullLink = () => {
        if (fullLink) {
            return fullLinkFooter;
        }
        return null;
    };
    function renderIfButtonLabel() {
        if (buttonLabel) {
            return linkButton ? (React.createElement(React.Fragment, null,
                React.createElement(Button, { className: btnClassNameList, onClick: onButtonClick, element: "a", icon: "semantic-forward", link: true, small: true, ...buttonProps }, buttonLabel))) : (React.createElement(React.Fragment, null,
                React.createElement(Button, { className: btnClassNameList, onClick: onButtonClick, ...buttonProps }, buttonLabel)));
        }
        return null;
    }
    return (React.createElement("section", { ...otherProps, "data-testid": testId, className: sectionClassNameList },
        React.createElement("div", { className: "c-media-teaser__media" }, video || (imgProps && React.createElement("img", { className: "c-media-teaser__image", alt: "", ...imgProps }))),
        React.createElement("div", { className: "c-media-teaser__caption" },
            React.createElement("div", { className: "c-media-teaser__pretitle" }, pretitle),
            React.createElement("h3", { className: "c-media-teaser__title" }, title),
            React.createElement("div", { className: "c-media-teaser__body" }, children),
            React.createElement("div", { className: "c-media-teaser__footer" },
                renderIfFullLink(),
                renderIfButtonLabel()))));
}
