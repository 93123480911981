/**
 * Get text content from node.
 *
 * @param {React.ReactNode} node - A React element.
 * @return {string} - The text content.
 *
 * @example
 *     getTextContent(<Component/>)
 *     getTextContent([<Component/>, <Component/>, ...])
 *     getTextContent('Lorem ipsum dolor ...')
 *     getTextContent(99.99)
 */
export default function getTextContent(node) {
    const type = typeof node;
    if (type === 'string' || type === 'number') {
        return node;
    }
    if (Array.isArray(node)) {
        return node.map(getTextContent).join('');
    }
    if (type === 'object' && node) {
        return getTextContent(node?.props?.children);
    }
    return '';
}
