import React, { useState } from 'react';
import classNames from 'classnames';
import { BasicSlider, useBasicSlider } from '../BasicSlider/BasicSlider';
/**
 * The CardsSlider provides a space-saving way for adding an arbitrary
 * amount of cards. If the amount of cards exceeds the horizontal space,
 * a carousel slider is automatically created. This gives the user
 * the possibility to reach all available cards by sliding horizontally.
 *
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-cards-slider.html
 *
 * @constructor
 */
export function CardsSlider({ className, cellAlign = 'left', groupCells = true, children, testId, ...otherProps }) {
    const initialResizeThreshold = 300;
    const [flickityRef, setFlickityRef] = useState(null);
    useBasicSlider(flickityRef, children, { initialResizeThreshold });
    const flickityOptions = {
        cellSelector: '.c-cards-slider__item',
        pageDots: true,
        prevNextButtons: true,
        groupCells,
        cellAlign,
        contain: false,
        freeScroll: true,
    };
    const classNameList = classNames('c-cards-slider-container', className).trim();
    const flickityClassNameList = classNames('c-cards-slider').trim();
    return (React.createElement("div", { className: classNameList, "data-testid": testId, ...otherProps },
        React.createElement(BasicSlider, { elementType: "div", className: flickityClassNameList, flickityRef: (c) => setFlickityRef(c), options: flickityOptions, onReady: (c) => c?.element?.parentElement?.classList.add('is-initialized') }, children)));
}
/**
 * The CardsSliderItem component is internally used to wrap
 * the item content and display it inside the {@link CardsSlider}.
 *
 * @param {string} [className] - Additional CSS classes on the component element.
 * @param {React.ReactNode} [children] - The content to display inside the {@link CardsSliderItem} component.
 * @param {string} [testId] - The `[data-testid]` attribute on the component element.
 * @param {Object} [otherProps] - Other props passed to the component element.
 * @return {JSX.Element} - The {@link CardsSliderItem} component.
 * @constructor
 */
function CardsSliderItem({ className, children, testId, ...otherProps }) {
    const classNameList = classNames('c-cards-slider__item', className).trim();
    return (React.createElement("div", { className: classNameList, "data-testid": testId, ...otherProps },
        React.createElement("div", { className: "c-cards-slider__card-wrapper" }, children)));
}
CardsSliderItem.displayName = 'CardsSlider.Item';
CardsSlider.Item = CardsSliderItem;
