import React from 'react';
import { InlineGroup, InlineGroupItem } from '../InlineGroup/InlineGroup';
import { FormFieldContext } from '../FormField/FormFieldContext';
/*
 * Bronson RadioButtonGroup component.
 */
export function RadioButtonGroup({ children, grid, small, testId, ...otherProps }) {
    /**
     * Track internal state via the wrapping {@link FormFieldContext}.
     * @type {object}
     */
    const context = React.useContext(FormFieldContext);
    function renderChildren() {
        return React.Children.map(children, (child) => {
            if (!child) {
                return null;
            }
            return React.createElement(InlineGroupItem, { ...otherProps }, child);
        });
    }
    return (React.createElement("fieldset", { role: "radiogroup", "aria-invalid": context.errorMessage ? true : undefined, "data-testid": testId, ...otherProps },
        React.createElement(InlineGroup, { grid: grid, small: small }, renderChildren())));
}
