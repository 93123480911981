// eslint-disable-next-line @typescript-eslint/no-explicit-any

interface IObjectKeys {
    [key: string]: string;
}

export function trimAllValues<Type>(inputValue?: Type & (object | IObjectKeys[])): Type {
    if (inputValue === null || (!Array.isArray(inputValue) && typeof inputValue != 'object')) return inputValue as Type;
    return Object.keys(inputValue).reduce(
        function(value, key) {
            const keyValue = (inputValue as IObjectKeys)[key];
            (value as IObjectKeys)[key] =
                typeof keyValue == 'string'
                    ? (inputValue as IObjectKeys)[key].trim()
                    : trimAllValues<typeof keyValue>(keyValue);
            return value;
        },
        Array.isArray(inputValue) ? [] : {},
    ) as Type;
}
