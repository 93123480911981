import * as React from 'react';
import { getUpdatedIndex } from './ComboboxCore';
/**
 * The ComboboxContext holds the state of the current component
 * including selected values/options, open state, multiple state
 * and current focused options.
 * @type {React.Context<{}>}
 */
const ComboboxContext = React.createContext([]);
/**
 * Allowed reducer actions on the state.
 */
export const comboboxReducerActions = {
    toggleOption: 'TOGGLE_OPTION',
    setOption: 'SET_OPTION',
    setFilterQuery: 'SET_FILTER_QUERY',
    openMenu: 'OPEN_MENU',
    closeMenu: 'CLOSE_MENU',
    toggleMenu: 'TOGGLE_MENU',
    focusOption: 'FOCUS_OPTION',
    focusOptionByAction: 'FOCUS_OPTION_BY_ACTION',
    focusOptionByLabel: 'FOCUS_OPTION_BY_TYPE',
    focusPrevOption: 'FOCUS_PREV_OPTION',
    focusNextOption: 'FOCUS_NEXT_OPTION',
    blurOption: 'BLUR_OPTION',
    setTouched: 'SET_TOUCHED',
};
/**
 * Sort the value array like the options array.
 * @param {ComboboxOption[]} values
 * @param {ComboboxOption[]} options
 * @returns {*}
 */
export const getSortedValues = (values, options) => {
    if (!Array.isArray(options)) {
        return values;
    }
    /**
     * Sort user values according to options.
     */
    return values.sort((a, b) => options.findIndex((o) => a.key === o.key) - options.findIndex((o) => b.key === o.key));
};
/**
 * Our state update logic written as reducer pattern.
 * @param {ComboboxState} state - The state to update.
 * @param {ComboboxStateAction} action - Allowed actions types and payload.
 * @returns {ComboboxState} - Returns a new state object.
 */
export function comboboxReducer(state, action) {
    switch (action.type) {
        case comboboxReducerActions.setOption: {
            /**
             * State update logic to set/activate an option:
             * 1. Check if multiple options are allowed.
             * 2. Check the value is present in state.
             * 3. Return the current state if so.
             * 4. Merge it with the current state if absent.
             * 5. Otherwise, return the current payload/value as the new selected for single mode.
             * @type {ComboboxOption[]|*[]}
             */
            // eslint-disable-next-line no-nested-ternary
            const nextState = state.isMulti // [1]
                ? state.value.find(({ key }) => key === action.payload?.key) // [2]
                    ? state.value // [3]
                    : [...state.value, ...[action.payload]] // [4]
                : [action.payload]; // [5]
            return { ...state, value: nextState, filterQuery: '', touched: true };
        }
        case comboboxReducerActions.toggleOption: {
            /**
             * State update logic for toggling an option:
             * 1. Check if multiple options are allowed.
             * 2. Check the value is present in state.
             * 3. Exclude the current value if so.
             * 4. Merge it with the current state if absent.
             * 5. Otherwise, return the current payload/value as the new selected for single mode.
             * @type {ComboboxOption[]|*[]}
             */
            // eslint-disable-next-line no-nested-ternary
            const nextState = state.isMulti // [1]
                ? state.value.find(({ key }) => key === action.payload?.key) // [2]
                    ? state.value.filter(({ key }) => key !== action.payload?.key) // [3]
                    : [...state.value, ...[action.payload]] // [4]
                : [action.payload]; // [5]
            return { ...state, touched: true, value: nextState };
        }
        case comboboxReducerActions.openMenu: {
            const firstValue = getSortedValues(state.value, state.options)?.[0];
            /**
             * If a value was selected before restore the focus on this value.
             */
            if (firstValue && !state.isOpen) {
                const nextKey = state.options.find((o) => o.key === firstValue?.key);
                const nextIndex = state.options.findIndex((o) => o.key === firstValue.key);
                if (nextKey && nextIndex) {
                    return {
                        ...state,
                        isOpen: true,
                        touched: true,
                        focusedOption: nextKey,
                        currentIndex: nextIndex,
                    };
                }
            }
            return {
                ...state,
                isOpen: true,
                touched: true,
            };
        }
        case comboboxReducerActions.closeMenu: {
            return {
                ...state,
                isOpen: false,
                filterQuery: '',
            };
        }
        case comboboxReducerActions.toggleMenu: {
            const firstValue = getSortedValues(state.value, state.options)?.[0];
            /**
             * If a value was selected before restore the focus on this value.
             */
            if (firstValue && !state.isOpen) {
                const nextKey = state.options.find((o) => o.key === firstValue?.key);
                const nextIndex = state.options.findIndex((o) => o.key === firstValue.key);
                if (nextKey && nextIndex) {
                    return {
                        ...state,
                        isOpen: !state.isOpen,
                        touched: true,
                        focusedOption: nextKey,
                        currentIndex: nextIndex,
                    };
                }
            }
            return {
                ...state,
                isOpen: !state.isOpen,
                touched: true,
            };
        }
        case comboboxReducerActions.focusOption: {
            const nextKey = state.options.find(({ key }) => action.payload?.key === key);
            const nextIndex = state.options.findIndex(({ key }) => action.payload?.key === key);
            return {
                ...state,
                focusedOption: nextKey,
                currentIndex: nextIndex,
            };
        }
        case comboboxReducerActions.focusOptionByLabel: {
            const nextKey = state.options.find(({ value }) => value.toLowerCase().startsWith(action.payload?.label));
            const nextIndex = state.options.findIndex(({ key }) => nextKey?.key === key);
            /**
             * If current key could not be found yielding an index of -1,
             * return the current state.
             */
            if (nextIndex < 0) {
                return state;
            }
            return {
                ...state,
                focusedOption: nextKey,
                currentIndex: nextIndex,
            };
        }
        case comboboxReducerActions.focusOptionByAction: {
            const visibleOptions = state.options.filter(({ value }) => value?.toLowerCase().includes(state.filterQuery?.toLowerCase() ?? ''));
            /**
             * Resolve the next/prev option index with handling disable states.
             * @param {number} currentIndex
             * @param {number} length
             * @param {number} currentAction
             * @returns {number|number|*|number}
             */
            const resolveIndex = (currentIndex, length, currentAction) => {
                const nextIndex = currentIndex === null ? 0 : getUpdatedIndex(currentIndex, length, currentAction);
                const nextKey = visibleOptions[nextIndex];
                /**
                 * If a search yields only one disabled element return it.
                 */
                if (nextKey?.disabled && length <= 1) {
                    return nextIndex;
                }
                if (nextKey?.disabled) {
                    /**
                     * If the currentIndex and nextIndex are equal, increment it manually to prevent an infinite loop.
                     */
                    return resolveIndex(currentIndex === 0 && nextIndex === 0 ? nextIndex + 1 : nextIndex, length, currentAction);
                }
                return nextIndex;
            };
            const nextIndex = resolveIndex(state.currentIndex, visibleOptions.length, action.payload?.action);
            const nextKey = visibleOptions[nextIndex];
            return {
                ...state,
                focusedOption: nextKey,
                currentIndex: nextIndex,
            };
        }
        case comboboxReducerActions.focusPrevOption: {
            const currentIndex = state.options.findIndex(({ key }) => state.focusedOption?.key === key) ?? 0;
            const prevIndex = currentIndex > 0 ? currentIndex - 1 : 0;
            const prevOption = state.options[prevIndex];
            return {
                ...state,
                focusedOption: prevOption,
                currentIndex: prevIndex,
            };
        }
        case comboboxReducerActions.focusNextOption: {
            const currentIndex = state.options.findIndex(({ key }) => state.focusedOption?.key === key) ?? 0;
            const nextIndex = currentIndex < state.options.length - 1 ? currentIndex + 1 : currentIndex;
            const nextOption = state.options[nextIndex];
            return {
                ...state,
                focusedOption: nextOption,
                currentIndex: nextIndex,
            };
        }
        case comboboxReducerActions.blurOption: {
            return {
                ...state,
                focusedOption: undefined,
                currentIndex: null,
            };
        }
        case comboboxReducerActions.setFilterQuery: {
            return {
                ...state,
                currentIndex: null,
                filterQuery: action.payload,
                /**
                 * Check if any option matches the filter payload.
                 */
                hasOptions: state.options.some(({ value }) => value?.toLowerCase().includes(action.payload?.toLowerCase())),
            };
        }
        case comboboxReducerActions.setTouched: {
            return {
                ...state,
                touched: true,
            };
        }
        default: {
            throw new Error(`UNHANDLED ACTION TYPE: ${action.type}`);
        }
    }
}
/**
 * Convenience helper to create a context provider.
 * @param children - The component or children to wrap in the context.
 * @param initialValue - Initial state.
 * @returns {JSX.Element} - Returns a context {@link ComboboxProvider}.
 * @constructor
 */
function ComboboxProvider({ children, initialValue }) {
    const [state, dispatch] = React.useReducer(comboboxReducer, initialValue);
    const value = React.useMemo(() => [state, dispatch], [state]);
    return React.createElement(ComboboxContext.Provider, { value: value }, children);
}
/**
 * State update hook.
 * @returns {{}}
 */
function useCombobox() {
    const context = React.useContext(ComboboxContext);
    /**
     * Throw an exception if the hook is used outside a {@link ComboboxContext.Provider}.
     */
    if (context === undefined) {
        throw new Error('useCombobox must be used within a ComboboxProvider');
    }
    return context;
}
export { ComboboxProvider, useCombobox };
