import React from 'react';
import classNames from 'classnames';
/**
 * Bronson TileSelectGroup component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-tile-select-group.html
 * @constructor
 */
export function TileSelectGroup({ children, className, layoutItemClassName, testId, ...otherProps }) {
    function renderChildren() {
        return React.Children.map(children, (child) => {
            if (!child) {
                return null;
            }
            const layoutItemClassNameList = classNames('o-layout__item', layoutItemClassName).trim();
            return React.createElement("div", { className: layoutItemClassNameList }, child);
        });
    }
    const classNameList = classNames('o-layout', 'o-layout--equal-height', className).trim();
    return (React.createElement("div", { ...otherProps, "data-testid": testId, className: classNameList }, renderChildren()));
}
