const BRONSON_CDN_BASE_URL = 'https://cdn.bronson.vwfs.io';
/**
 * Hook used to get the Bronson CDN path.
 * Determined from env variables, either
 * - REACT_APP_BRONSON_BRAND and REACT_APP_BRONSON_VERSION
 * - REACT_APP_WEBSITE_BRONSON_THEME and REACT_APP_WEBSITE_BRONSON_VERSION
 * - REACT_APP_WEBSITE_THEME
 *
 * @returns object with properties
 * - `cdn` - path to Bronson CDN
 * - `theme` - Bronson theme, e.g. 'audi'
 * - `version` - Bronson version, e.g. 7.30.0
 */
export const useBronson = () => {
    const bronson = {
        theme: null,
        version: null,
        cdn: null,
    };
    if (process.env.REACT_APP_BRONSON_BRAND && process.env.REACT_APP_BRONSON_VERSION) {
        // See 'gondor' projects
        bronson.theme = process.env.REACT_APP_BRONSON_BRAND;
        bronson.version = process.env.REACT_APP_BRONSON_VERSION;
    }
    else if (process.env.REACT_APP_WEBSITE_BRONSON_THEME && process.env.REACT_APP_WEBSITE_BRONSON_VERSION) {
        bronson.theme = process.env.REACT_APP_WEBSITE_BRONSON_THEME;
        bronson.version = process.env.REACT_APP_WEBSITE_BRONSON_VERSION;
    }
    else if (process.env.REACT_APP_WEBSITE_THEME) {
        // e.g. cc-nl-frontend with 'vw6/v/1.12.0'
        // eslint-disable-next-line prefer-regex-literals
        const splitted = process.env.REACT_APP_WEBSITE_THEME.split(new RegExp('(.+)/v/(\\d+\\.\\d+\\.\\d+)'));
        if (splitted.length >= 2) {
            // eslint-disable-next-line prefer-destructuring
            bronson.theme = splitted[1];
            // eslint-disable-next-line prefer-destructuring
            bronson.version = splitted[2];
        }
    }
    if (bronson.theme && bronson.version) {
        bronson.cdn = `${BRONSON_CDN_BASE_URL}/${bronson.theme}/v/${bronson.version}`;
    }
    return bronson;
};
