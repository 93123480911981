import React from 'react';
import classNames from 'classnames';
/**
 * The UnorderedList component is an ordinary `<ul>` element,
 * used for proper styling with custom bullet points.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-unordered-list.html
 * @constructor
 */
export const UnorderedList = ({ children, testId, ...otherProps }) => {
    const ulClassNameList = classNames('c-ul').trim();
    return (React.createElement("ul", { ...otherProps, "data-testid": testId, className: ulClassNameList }, children));
};
/**
 * The Item component (nested) is internally used to wrap
 * the item content and display it inside the {@link UnorderedList}.
 */
function Item({ children, ...otherProps }) {
    return React.createElement("li", { ...otherProps }, children);
}
Item.displayName = 'UnorderedList.Item';
UnorderedList.Item = Item;
