import React from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
/**
 * Bronson InstructionStepper component.
 * @constructor
 */
export function InstructionStepper({ center, children, className, displayIcons = true, numbered, small, reversed, testId, vertical, ...otherProps }) {
    const olClassNameList = classNames('c-instruction-stepper ', {
        'c-instruction-stepper--numbered ': numbered,
        'c-instruction-stepper--vertical ': vertical,
        'c-instruction-stepper--center ': center,
        'c-instruction-stepper--small ': small,
        'c-instruction-stepper--reversed ': reversed, // Convenience prop from Bronson variants.
    }, className).trim();
    /**
     * Determine whether this stepper should display icons.
     * The `numbered` prop overrules the `displayIcons` prop to avoid number/icon collisions.
     * @type {boolean}
     */
    const displayIcon = !numbered && displayIcons;
    return (React.createElement("ol", { ...otherProps, "data-testid": testId, className: olClassNameList }, React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { displayIcon });
        }
    }) /* Use {@link InstructionStepperStep} component. */));
}
/**
 * Bronson InstructionStepperStep component (nested).
 * @internal
 * @constructor
 */
function InstructionStepperStep({ children, displayIcon, icon, state, title, ...otherProps }) {
    const liClassNameList = classNames('c-instruction-stepper__step ', { [`${state} `]: state }).trim();
    function renderIfDisplayIcons() {
        if (displayIcon) {
            return React.createElement(Icon, { name: icon });
        }
        return null;
    }
    function renderIfDescription() {
        if (children) {
            return React.createElement("span", { className: "c-instruction-stepper__description" }, children);
        }
        return null;
    }
    return (React.createElement("li", { ...otherProps, className: liClassNameList },
        React.createElement("figure", { className: "c-instruction-stepper__item" }, renderIfDisplayIcons()),
        React.createElement("div", { className: "c-instruction-stepper__detail" },
            React.createElement("span", { className: "c-instruction-stepper__title" }, title),
            renderIfDescription())));
}
InstructionStepperStep.displayName = 'InstructionStepper.Step';
InstructionStepper.Step = InstructionStepperStep;
