import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { Spinner } from '../Spinner/Spinner';
import { Icon } from '../Icon/Icon';
/**
 * Bronson Upload component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-upload.html
 * @constructor
 */
export function Upload({ accept, buttonText, className, descriptionSupplementaryText, descriptionText, disabled, disabledSupplementaryText, disabledText, id, loading, mouseover, mouseoverText, multiple, name, onChange, processingText, testId, ...otherProps }) {
    const [hasHover, setHasHover] = useState(false);
    const uploadElement = useRef(null);
    /**
     * Handle side-effects for Upload Mouseover variant.
     */
    useEffect(() => {
        const current = uploadElement?.current;
        /**
         * Add `.has-mouseover` class dynamically
         * when {@link mouseover} is enabled.
         */
        if (mouseover && current) {
            const handleMouseEnter = () => {
                setHasHover(true);
            };
            const handleMouseLeave = () => {
                setHasHover(false);
            };
            current.addEventListener('mouseenter', handleMouseEnter);
            current.addEventListener('mouseleave', handleMouseLeave);
            /**
             * Cleanup function.
             */
            return () => {
                current.removeEventListener('mouseenter', handleMouseEnter);
                current.removeEventListener('mouseleave', handleMouseLeave);
            };
        }
        return () => { };
    }, [mouseover]);
    const labelClassNameList = classNames('c-upload', {
        // Convenience prop from Bronson variants.
        'has-mouseover': mouseover && hasHover,
        'is-loading': loading,
        'is-disabled': disabled,
    }, className).trim();
    function renderIfUploadButtonText() {
        if (buttonText) {
            return (React.createElement("span", { className: "c-upload__actions" },
                React.createElement("span", { className: "c-upload__button c-btn" },
                    React.createElement("span", null, buttonText))));
        }
        return null;
    }
    function renderIfUploadDisabledSupplementaryText() {
        if (disabledSupplementaryText) {
            return React.createElement("span", { className: "c-upload__disabled-supplementary-text" }, disabledSupplementaryText);
        }
        return null;
    }
    return (React.createElement("label", { ...otherProps, "data-testid": testId, className: labelClassNameList, htmlFor: id, ref: uploadElement },
        React.createElement("input", { className: "c-upload__input", type: "file", id: id, onChange: onChange, accept: accept, multiple: multiple, name: name, disabled: disabled }),
        React.createElement("span", { className: "c-upload__description" },
            React.createElement(Icon, { className: "c-upload__icon", viaCss: true }),
            React.createElement("span", { className: "c-upload__description-text" }, descriptionText),
            React.createElement("span", { className: "c-upload__description-supplementary-text" }, descriptionSupplementaryText)),
        renderIfUploadButtonText(),
        React.createElement("span", { className: "c-upload__mouseover" },
            React.createElement(Icon, { className: "c-upload__mouseover-icon", viaCss: true }),
            React.createElement("span", { className: "c-upload__mouseover-text" }, mouseoverText)),
        React.createElement("span", { className: "c-upload__loader" },
            React.createElement(Spinner, { center: true }),
            React.createElement("span", { className: "c-upload__loader-text" }, processingText || 'Processing...')),
        React.createElement("span", { className: "c-upload__disabled" },
            React.createElement(Icon, { className: "c-upload__disabled-icon", viaCss: true }),
            React.createElement("span", { className: "c-upload__disabled-text" }, disabledText),
            renderIfUploadDisabledSupplementaryText())));
}
