import React, { isValidElement, useMemo, useState } from 'react';
import classNames from 'classnames';
import { BasicSlider, useBasicSlider } from '../BasicSlider/BasicSlider';
import { Icon } from '../Icon/Icon';
const TabsContext = React.createContext({});
/**
 * Hook to manage external tab selection.
 * @param {string} [defaultSelected] - A tab `[id]` attribute value.
 * @return {[unknown, ((value: unknown) => void)]}
 */
export const useTabSelector = (defaultSelected) => {
    const [selected, setSelected] = useState(defaultSelected);
    return [selected, setSelected];
};
/**
 * Bronson Tabs component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-tabs.html
 * @constructor
 */
export function Tabs({ children, className, defaultSelected, onChangeSelected, selected, testId, ...otherProps }) {
    /**
     * To be downward compatible we still use this internal state when `defaultSelected` is available.
     */
    const [currSelected, setCurrSelected] = useState(defaultSelected);
    /**
     * Change the `isSelected()` and `setSelected()` functions depending on the `defaultSelected` state.
     * This is due the fact that either `defaultSelected` or `['selected, 'onChangeSelected']` can be used.
     * @type {{setSelected: (function(*=): void), isSelected: (function(*): boolean), selected: string}}
     */
    const contextValue = useMemo(() => {
        return defaultSelected
            ? {
                defaultSelected,
                selected: currSelected,
                // query whether the tab id is selected
                isSelected: (tabId) => currSelected === tabId,
                // callback function to set the selected tab
                setSelected: (tabId) => setCurrSelected(tabId),
            }
            : {
                defaultSelected: selected,
                selected,
                // query whether the tab id is selected
                isSelected: (tabId) => selected === tabId,
                // callback function to set the selected tab
                setSelected: (tabId) => onChangeSelected?.(tabId),
            };
    }, [currSelected, defaultSelected, onChangeSelected, selected]);
    const classNameList = classNames('c-tabs', className).trim();
    return (React.createElement("div", { ...otherProps, className: classNameList, "data-testid": testId },
        React.createElement(TabsContext.Provider, { value: contextValue }, children)));
}
/**
 * Bronson TabsHeader component (nested).
 * @constructor
 */
function TabsHeader({ children, className, icons, iconsOnly, stacked, testId, ...otherProps }) {
    const initialResizeThreshold = 100;
    const { defaultSelected, selected } = React.useContext(TabsContext);
    const [flickityRef, setFlickityRef] = useState(null);
    /**
     * Set the initialIndex depending on the current select tab.
     * If the default and selected tab mismatch we assume the user has navigated themselves.
     * @type {number|undefined}
     */
    const initialIndex = defaultSelected === selected
        ? React.Children.toArray(children).findIndex((c) => {
            if (isValidElement(c)) {
                return c.props.tabId === defaultSelected;
            }
            return false;
        })
        : undefined;
    useBasicSlider(flickityRef, children, { hasDynamicElements: false, initialIndex, initialResizeThreshold });
    const tabsClassNameList = classNames('c-tabs__nav', {
        'c-tabs__nav--icons': icons,
        'c-tabs__nav--icons-stacked': stacked,
        'c-tabs__nav--icons-only': iconsOnly,
    }, className).trim();
    const flickityOptions = {
        cellSelector: '.c-tabs__nav-item',
        contain: true,
        pageDots: false,
        cellAlign: 'left',
        groupCells: true,
        freeScroll: true,
        prevNextButtons: true,
        initialIndex,
    };
    return (React.createElement(BasicSlider, { elementType: "div", className: tabsClassNameList, role: "tablist", flickityRef: (c) => setFlickityRef(c), options: flickityOptions, "data-testid": testId, ...otherProps }, children));
}
TabsHeader.displayName = 'Tabs.Header';
Tabs.Header = TabsHeader;
/**
 * Bronson TabsHeaderItem component (nested).
 * @constructor
 */
function TabsHeaderItem({ children, className, disabled, icon, tabId, testId, ...otherProps }) {
    const context = React.useContext(TabsContext);
    const buttonClassNameList = classNames('c-tabs__nav-link', {
        'is-disabled ': disabled,
        'is-active ': !disabled && context?.isSelected?.(tabId),
    }, className).trim();
    const iClassNameList = classNames('c-tabs__icon').trim();
    function renderIfTabsItemIcon() {
        if (icon) {
            return React.createElement(Icon, { className: iClassNameList, name: icon });
        }
        return null;
    }
    return (React.createElement("div", { ...otherProps, className: "c-tabs__nav-item", "data-testid": testId, role: "presentation" },
        React.createElement("button", { id: tabId, className: buttonClassNameList, "aria-expanded": !disabled && context?.isSelected?.(tabId), "aria-selected": !disabled && context?.isSelected?.(tabId), disabled: disabled, role: "tab", tabIndex: !disabled && context?.isSelected?.(tabId) ? -1 : undefined, onClick: () => !disabled && context?.setSelected?.(tabId), type: "button" },
            renderIfTabsItemIcon(),
            React.createElement("span", { className: "c-tabs__text" }, children))));
}
TabsHeaderItem.displayName = 'Tab.HeaderItem';
Tabs.HeaderItem = TabsHeaderItem;
/**
 * Bronson TabsContent component (nested).
 * @constructor
 */
function TabsContent({ children, testId, ...otherProps }) {
    return (React.createElement("div", { "data-testid": testId, ...otherProps, className: "c-tabs__content-wrapper" }, children));
}
TabsContent.displayName = 'Tabs.Content';
Tabs.Content = TabsContent;
/**
 * Bronson TabsContentItem component (nested).
 * @constructor
 */
function TabsContentItem({ children, className, tabId, testId, ...otherProps }) {
    const context = React.useContext(TabsContext);
    const classNameList = classNames('c-tabs__content', className).trim();
    return (
    /**
     * Need to pass `[inert=""]` as React currently does not support HTMLElement.inert mapping.
     * @see https://github.com/facebook/react/pull/24730
     * @TODO: Remove once React supports direct `[inert]` usage.
     * @see BRON-11871
     */
    React.createElement("div", { ...otherProps, className: classNameList, "aria-hidden": context?.isSelected?.(tabId) ? 'false' : 'true', "aria-labelledby": tabId, 
        // @ts-ignore @TODO: Remove once React supports inerts, @see BRON-11871.
        inert: context.isSelected(tabId) ? null : '', "data-testid": testId, role: "tabpanel" }, children));
}
TabsContentItem.displayName = 'Tabs.ContentItem';
Tabs.ContentItem = TabsContentItem;
