import React from 'react';
/*
 * Bronson ErrorMessage component.
 */
export function ErrorMessage({ id, children, testId, ...otherProps }) {
    /**
     * Throw a console warning when `id` prop is not used.
     */
    if (!id) {
        console.warn('Warning: The `id` property is required for accessibility reasons to assign the element that returns an error message.');
    }
    return (React.createElement("p", { className: "c-error-message", id: id ? `error-${id}` : undefined, "data-testid": testId, ...otherProps }, children));
}
