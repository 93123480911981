import React from 'react';
import classNames from 'classnames';
/**
 * Bronson Spinner component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-spinner.html
 * @constructor
 */
export function Spinner({ busy = true, center, children, className, fullPage, section, small, testId, ...otherProps }) {
    // hint: combination of text and section for spinner is not (yet) possible
    const hasText = !section && children;
    const divClassNameList = classNames('c-spinner', {
        // main class modifier convenience prop extension
        'c-spinner--small ': small,
        'c-spinner--center ': center,
        'c-spinner--full-page ': fullPage,
        'c-spinner--text ': hasText,
        'c-spinner--section ': section, // Convenience prop from Bronson variants.
    }, className).trim();
    function renderLoader() {
        return (React.createElement("div", { className: "c-spinner__loader" },
            React.createElement("span", { className: "c-spinner__element" }),
            React.createElement("span", { className: "c-spinner__element" }),
            React.createElement("span", { className: "c-spinner__element" }),
            React.createElement("span", { className: "c-spinner__element" }),
            React.createElement("span", { className: "c-spinner__element" }),
            React.createElement("span", { className: "c-spinner__element" }),
            React.createElement("span", { className: "c-spinner__element" }),
            React.createElement("span", { className: "c-spinner__element" })));
    }
    function renderIfHasText() {
        if (hasText) {
            return (React.createElement("div", { className: "c-spinner__content" },
                renderLoader(),
                React.createElement("div", { className: "c-spinner__text" }, children)));
        }
        return null;
    }
    function renderIfNotHasText() {
        if (!hasText) {
            return (React.createElement(React.Fragment, null,
                children,
                renderLoader()));
        }
        return null;
    }
    return (React.createElement("div", { ...otherProps, "data-testid": testId, className: divClassNameList, "aria-live": "polite", "aria-busy": busy },
        renderIfHasText(),
        renderIfNotHasText()));
}
