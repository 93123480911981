/* eslint-disable react-hooks/exhaustive-deps */
// TODO: check deps
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { InfoIcon } from '../InfoIcon/InfoIcon';
import uuidv4 from '../../utils/uuidv4';
const AccordionContext = React.createContext(null);
/*
 * Bronson DescriptionListAccordion component.
 */
export function DescriptionListAccordion({ 
// defaultOpen,
children, className, headerSplit, multipleOpen, totalLabel, totalLabelNote, totalValue, withBackground, testId, ...otherProps }) {
    const [open, setOpen] = useState([]);
    const contextValue = {
        // the value of "open" state
        isOpen,
        // callback function to toggle the open states
        toggleOpen: (ref) => toggleOpen(ref),
        // callback function to update the open states
        updateOpen: (ref, refToBeOpen) => updateOpen(ref, refToBeOpen),
        headerSplit,
    };
    function isOpen(ref) {
        return open.indexOf(ref) >= 0;
    }
    /*
     * Updates the open state for a given item (ref) with a target condition (refToBeOpen bool).
     * Subsequently updates other states accordingly.
     */
    function updateOpen(ref, refToBeOpen) {
        if (multipleOpen) {
            const index = open.indexOf(ref);
            if (refToBeOpen) {
                // should be open
                if (index < 0) {
                    // not yet in open list, add
                    setOpen(open.concat(open, [ref]));
                }
                // should not be open
            }
            else if (index >= 0) {
                // present in list, remove
                setOpen(open.filter((value) => value !== ref));
            }
        }
        else if (refToBeOpen) {
            // set as only open
            setOpen([ref]);
        }
        else {
            // set none to be open
            setOpen([]);
        }
    }
    /*
     * Change open state for the given item (ref) without the need to know the current state.
     */
    function toggleOpen(ref) {
        updateOpen(ref, !(open.indexOf(ref) >= 0));
    }
    const divClassNameList = classNames('c-accordion', 'c-description-list-accordion__accordion', {
        // Main class modifier convenience prop extension.
        'c-accordion--bg-panel ': withBackground,
        'c-accordion--header-split': headerSplit,
    }, className).trim();
    return (React.createElement("section", { className: "c-description-list-accordion" },
        React.createElement("div", { ...otherProps, "data-testid": testId, className: divClassNameList, "data-accordion-multiple": !multipleOpen && 'true' },
            React.createElement(AccordionContext.Provider, { value: contextValue }, children)),
        React.createElement("dl", { className: "c-description-list-accordion__item  c-description-list-accordion__item--total" },
            React.createElement("dt", { className: "c-description-list-accordion__item__label" },
                React.createElement("div", { className: "c-description-list-accordion__item__label-text" }, totalLabel),
                React.createElement("div", { className: "c-description-list-accordion__item__label-note" }, totalLabelNote)),
            React.createElement("dd", { className: "c-description-list-accordion__item__value" }, totalValue))));
}
/**
 * Bronson AccordionItem component.
 * @internal
 */
function DescriptionListAccordionItem({ children, className, defaultOpen, icon, nonCollapsable, subtitle, title, titleSplit, tooltip, testId, ...otherProps }) {
    const itemRef = useRef(uuidv4());
    const context = React.useContext(AccordionContext);
    useEffect(() => {
        context?.updateOpen(itemRef.current, !!defaultOpen);
    }, [defaultOpen]);
    const childDivClassNameList = classNames('c-accordion__header', 'c-description-list-accordion__header', {
        'is-active': context?.isOpen(itemRef.current),
    }, className).trim();
    /**
     * Needed e.g. because IconList within the panel would result in icons staying on screen even when collapsed.
     * This is handled by Bronson JS otherwise.
     */
    const panelStyle = {
        display: context?.isOpen(itemRef.current) ? 'inherit' : 'none',
    };
    const defaultCursor = nonCollapsable
        ? {
            cursor: 'default',
        }
        : {};
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: childDivClassNameList, onClick: () => {
                return !nonCollapsable && context?.toggleOpen(itemRef.current);
            }, "data-testid": testId, style: defaultCursor, ...otherProps },
            !nonCollapsable && React.createElement("span", { className: "c-accordion__title-icon" }),
            React.createElement("h3", { className: "c-accordion__title" }, nonCollapsable ? (React.createElement("div", { className: "c-accordion__title-label", style: defaultCursor },
                React.createElement("span", { className: "c-accordion__title-label-text c-description-list-accordion__title-label" },
                    title,
                    icon && (React.createElement(InfoIcon, { className: "c-accordion__title-info-icon", element: "button", icon: icon }, tooltip))),
                context?.headerSplit && (React.createElement("span", { className: "c-accordion__title-label-text c-description-list-accordion__title-value" }, titleSplit)))) : (React.createElement("span", { className: "c-accordion__title-label", "aria-expanded": context?.isOpen(itemRef.current) },
                React.createElement("span", { className: "c-accordion__title-label-text c-description-list-accordion__title-label" },
                    title,
                    icon && (React.createElement(InfoIcon, { className: "c-accordion__title-info-icon", element: "button", icon: icon }, tooltip))),
                context?.headerSplit && (React.createElement("span", { className: "c-accordion__title-label-text c-description-list-accordion__title-value" }, titleSplit))))),
            subtitle && React.createElement("p", { className: "c-accordion__subtitle" }, subtitle)),
        React.createElement("div", { className: "c-accordion__panel", "aria-hidden": !context?.isOpen(itemRef.current), style: panelStyle },
            React.createElement("div", { className: "c-accordion__content c-description-list-accordion__content" }, children))));
}
DescriptionListAccordionItem.displayName = 'DescriptionListAccordion.Item';
DescriptionListAccordion.Item = DescriptionListAccordionItem;
/*
 * Bronson DescriptionItem component.
 */
function DescriptionItem({ label, labelNote, children }) {
    return (React.createElement("dl", { className: "c-description-list-accordion__item" },
        React.createElement("dt", { className: "c-description-list-accordion__item__label" },
            React.createElement("div", { className: "c-description-list-accordion__item__label-text" }, label),
            labelNote && React.createElement("div", { className: "c-description-list-accordion__item__label-note" }, labelNote)),
        React.createElement("dd", { className: "c-description-list-accordion__item__value" }, children)));
}
DescriptionItem.displayName = 'DescriptionListAccordion.DL';
DescriptionListAccordion.DL = DescriptionItem;
