import React from 'react';
import { Link } from 'react-router-dom';
/**
 * Render a simple HTMLAnchorElement either as normal link or {@link Link} from `react-router-dom`.
 * @constructor
 * @internal
 */
export function ReactLink({ children, className, href, normalLink, onClick, target, to, testId, ...otherProps }) {
    /**
     * Optional link attributes depending on {@link normalLink}.
     * If we have a normal anchor link we only apply the `[href]` attribute,
     * if on the other hand we have a React Router {@link Link}, we apply `[to]`.
     * @type {{}}
     */
    const commonProps = {
        className: className,
        onClick,
        target: target,
        'data-testid': testId,
        ...otherProps,
    };
    return normalLink ? (React.createElement("a", { ...commonProps, href: href }, children)) : (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    React.createElement(Link, { ...commonProps, to: (to || href) }, children));
}
