/*
 * Helper constants and functions.
 */
// Make it easier for ourselves by putting some values in objects.
// In TypeScript, these would be enums.
export const Keys = {
    Backspace: 'Backspace',
    Clear: 'Clear',
    Down: 'ArrowDown',
    End: 'End',
    Enter: 'Enter',
    Escape: 'Escape',
    Home: 'Home',
    Left: 'ArrowLeft',
    PageDown: 'PageDown',
    PageUp: 'PageUp',
    Right: 'ArrowRight',
    Space: ' ',
    Tab: 'Tab',
    Up: 'ArrowUp',
};
export const MenuActions = {
    Close: 0,
    CloseSelect: 1,
    First: 2,
    Last: 3,
    Next: 4,
    Open: 5,
    Previous: 6,
    Select: 7,
    Space: 8,
    Next10: 9,
    Previous10: 10,
    Type: 11,
};
/**
 * Return Combobox action from key press.
 * @param {string|event} event - Key value from event object.
 * @param {boolean} menuOpen - If opening menu should be triggered.
 * @returns {number} - Action to be triggered.
 */
export function getActionFromKey(event, menuOpen) {
    const { key, altKey, ctrlKey, metaKey } = event;
    // Handle opening when closed.
    if (!menuOpen && (key === Keys.Down || key === Keys.Enter || key === Keys.Space)) {
        return MenuActions.Open;
    }
    // Handle keys when open.
    if (key === Keys.Down) {
        return MenuActions.Next;
    }
    if (key === Keys.Up) {
        return MenuActions.Previous;
    }
    if (key === Keys.Home) {
        return MenuActions.First;
    }
    if (key === Keys.End) {
        return MenuActions.Last;
    }
    if (key === Keys.Escape) {
        return MenuActions.Close;
    }
    if (key === Keys.Enter) {
        return MenuActions.CloseSelect;
    }
    if (key === Keys.Space) {
        return MenuActions.Select;
    }
    if (key === Keys.PageUp) {
        return MenuActions.Previous10;
    }
    if (key === Keys.PageDown) {
        return MenuActions.Next10;
    }
    if (key === 'Backspace' ||
        key === 'Clear' ||
        (key.length === 1 && key !== Keys.Space && !altKey && !ctrlKey && !metaKey)) {
        return MenuActions.Type;
    }
    return Infinity;
}
/**
 * Get valid index based on action, current and max index.
 * If the index exceeds the available length, e.g. 18/20 from is selected
 * and PAGEDOWN is pressed, the max (20) gets returned.
 * @param {number} current - Current index.
 * @param {number} length - Maximum available index.
 * @param {number} action - MenuAction enum.
 * @returns
 */
export function getUpdatedIndex(current, length, action) {
    const isLast = current === length - 1;
    switch (action) {
        case MenuActions.First:
            return 0;
        case MenuActions.Last:
            return length - 1;
        case MenuActions.Previous:
            return Math.max(current === 0 ? length - 1 : 0, current - 1);
        case MenuActions.Previous10:
            return Math.max(0, current - 10);
        case MenuActions.Next:
            return Math.min(isLast ? 0 : length, current + 1);
        case MenuActions.Next10:
            return Math.min(length, current + 10);
        default:
            return current;
    }
}
