import React from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
/**
 * Bronson PlayButton component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-play-button.html
 * @constructor
 */
export function PlayButton({ className, iconClassName, label, testId, ...otherProps }) {
    const buttonClassNameList = classNames('c-play-button', className).trim();
    const iconClassNameList = classNames('c-play-button__icon', iconClassName).trim();
    return (React.createElement("button", { className: buttonClassNameList, type: "button", "data-testid": testId, ...otherProps },
        React.createElement(Icon, { className: iconClassNameList, name: "play", viaCss: true }),
        React.createElement("span", { className: "c-play-button__label" }, label)));
}
