import React from 'react';
/**
 * Bronson UploadContainer component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-upload-container.html
 * @constructor
 */
export function UploadContainer({ children, upload, testId, ...otherProps }) {
    return (React.createElement("div", { className: "c-upload-container" },
        React.createElement("div", { className: "c-upload-container__field" }, upload),
        React.createElement("ul", { ...otherProps, "data-testid": testId, className: "c-upload-container__itemlist" }, children)));
}
/**
 * Bronson UploadContainerItem component (nested).
 * @constructor
 */
function UploadContainerItem({ children }) {
    return React.createElement("li", { className: "c-upload-container__item" }, children);
}
UploadContainerItem.displayName = 'UploadContainer.Item';
UploadContainer.Item = UploadContainerItem;
