import React from 'react';
import classNames from 'classnames';
/**
 * The OrderedList component is an ordinary `<ol>` element,
 * used for normal numeration lists in editorial content.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-ordered-list.html
 * @constructor
 */
export const OrderedList = ({ children, nestedCounter, testId, ...otherProps }) => {
    const olClassNameList = classNames({
        'c-ol': !nestedCounter,
        'c-list-nested-counter': nestedCounter,
    }).trim();
    return (React.createElement("ol", { ...otherProps, "data-testid": testId, className: olClassNameList }, children));
};
/**
 * The Item component (nested) is internally used to wrap
 * the item content and display it inside the {@link OrderedList}.
 */
function Item({ children, ...otherProps }) {
    return React.createElement("li", { ...otherProps }, children);
}
Item.displayName = 'OrderedList.Item';
OrderedList.Item = Item;
