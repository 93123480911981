import React from 'react';
/*
 * Bronson Footnote component.
 */
export function Footnote({ children, numbered, testId, ...otherProps }) {
    const CustomTag = `${numbered ? 'ol' : 'ul'}`;
    return (React.createElement(CustomTag, { ...otherProps, "data-testid": testId, className: "c-footnotes" }, children));
}
/*
 * Bronson FootnoteItem component (nested).
 */
function FootnoteItem({ bullet, children, ...otherProps }) {
    return (React.createElement("li", { ...otherProps, "data-footnote-bullet": bullet }, children));
}
FootnoteItem.displayName = 'Footnote.Item';
Footnote.Item = FootnoteItem;
