import React, { isValidElement } from 'react';
import classNames from 'classnames';
/**
 * Bronson Header component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-header.html
 * @constructor
 */
export function Header({ children, className, logo, notificationCenter, staticHeader, testId, ...otherProps }) {
    const classNameList = classNames('c-header', {
        'c-header--static': staticHeader, // Convenience prop from Bronson variants.
    }, className).trim();
    function renderIfNotificationCenter() {
        if (notificationCenter) {
            return React.createElement("div", { className: "c-header__notification-center" }, notificationCenter);
        }
        return null;
    }
    return (React.createElement("header", { className: "c-header__wrapper", "data-testid": testId, ...otherProps },
        React.createElement("div", { className: classNameList },
            React.createElement("div", { className: "c-header__content-wrapper" },
                React.createElement("div", { className: "c-header__logo" }, logo),
                React.Children.map(children, (child) => {
                    if (isValidElement(child)) {
                        return child.props.isContextBar ? child : React.createElement("div", { className: "c-header__nav" }, child);
                    }
                    return null;
                }),
                renderIfNotificationCenter()))));
}
