import { useEffect, useState } from 'react';
/**
 * Simple hook for utilizing `window.matchMedia`.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 *
 * @param {string} query - A mediaQueryString
 * @returns {boolean} - Returns a boolean indicating if the media matches.
 */
export const useMatchMedia = (query) => {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);
    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        media.addEventListener ? media.addEventListener('change', listener) : media.addListener(listener);
        /**
         * Cleanup function.
         */
        return () => media.removeEventListener ? media.removeEventListener('change', listener) : media.removeListener(listener);
    }, [matches, query]);
    return matches;
};
