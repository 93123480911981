import React from 'react';
import classNames from 'classnames';
import { ReactLink } from '../ReactLink/ReactLink';
/**
 * Bronson Breadcrumb component.
 *
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-breadcrumb.html
 *
 * @constructor
 */
export function Breadcrumb({ children, testId, ...otherProps }) {
    return (React.createElement("nav", { ...otherProps, "data-testid": testId },
        React.createElement("ul", { className: "c-breadcrumb" }, children)));
}
/**
 * Bronson BreadcrumbItem component (nested).
 * @internal
 * @constructor
 */
export function BreadcrumbItem({ children, current, disabled, href, normalLink, onClick, state, testId, ...otherProps }) {
    const aClassNameList = classNames('c-breadcrumb__link', {
        'is-current': current,
        'is-disabled': disabled,
        [`${state}`]: state,
    }).trim();
    return (React.createElement("li", { className: "c-breadcrumb__item", "data-testid": testId },
        React.createElement(ReactLink, { className: aClassNameList, normalLink: normalLink, onClick: onClick, href: href, "aria-current": current ? 'page' : undefined, ...otherProps },
            React.createElement("span", { className: "c-breadcrumb__label" }, children))));
}
BreadcrumbItem.displayName = 'Breadcrumb.Item';
Breadcrumb.Item = BreadcrumbItem;
