import React from 'react';
import classNames from 'classnames';
import { ReactLink } from '../ReactLink/ReactLink';
import { Icon } from '../Icon/Icon';
/*
 * Bronson ContextBar component.
 */
export const ContextBar = ({ children, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
isContextBar = true, testId, ...otherProps }) => {
    return (React.createElement("div", { className: "c-context-bar" },
        React.createElement("div", { className: "c-context-bar__panel", "aria-hidden": "true" },
            React.createElement("nav", { ...otherProps, "data-testid": testId, className: "c-context-bar__navs", "aria-label": "primary" }, children /* Use 'ContextBar.SiteNav' component. */))));
};
/*
 * Bronson SiteNav component (nested).
 */
function SiteNav({ children, ...otherProps }) {
    return (React.createElement("ul", { ...otherProps, className: "c-context-bar__nav" }, children /* Use 'ContextBar.SiteNav.Item' component. */));
}
/*
 * Bronson SiteNavItem component (nested).
 */
const SiteNavItem = ({ noLink, title, label, children, }) => {
    const spanClassNameList = classNames({
        'c-context-bar__title': title,
        'c-context-bar__nav-label': !title,
    }).trim();
    function renderIfNoLink() {
        if (noLink) {
            return (React.createElement("li", null,
                React.createElement("span", { className: spanClassNameList }, label)));
        }
        return null;
    }
    return (React.createElement(React.Fragment, null,
        renderIfNoLink(),
        children /* Use 'ContextBar.SiteNav.Item.ContextItem' component. */));
};
/*
 * Bronson ContextItem component (nested).
 */
function ContextItem({ contextBarIcon, href, iconLabel, linkProps, normalLink, onClick, ...otherProps }) {
    const iClassNameList = classNames('c-context-bar__icon').trim();
    function renderIfIconLabel() {
        if (iconLabel) {
            return React.createElement("span", { className: "c-context-bar__icon-label" }, iconLabel);
        }
        return null;
    }
    return (React.createElement("li", { ...otherProps },
        React.createElement(ReactLink, { className: "c-context-bar__link", onClick: onClick, href: href, normalLink: normalLink, ...linkProps },
            React.createElement(Icon, { className: iClassNameList, name: contextBarIcon }),
            renderIfIconLabel())));
}
ContextItem.displayName = 'ContextBar.SiteNav.Item.ContextItem';
SiteNavItem.ContextItem = ContextItem;
SiteNavItem.displayName = 'ContextBar.SiteNav.Item';
SiteNav.Item = SiteNavItem;
SiteNav.displayName = 'ContextBar.SiteNav';
ContextBar.SiteNav = SiteNav;
