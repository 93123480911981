import React from 'react';
import classNames from 'classnames';
/**
 * Bronson FormSectionGroup component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-form-section-group.html
 * @constructor
 */
export function FormSectionGroup({ children, className, testId, ...otherProps }) {
    const divClassNameList = classNames('c-form-section-group', className).trim();
    return (React.createElement("div", { ...otherProps, "data-testid": testId, className: divClassNameList }, children));
}
