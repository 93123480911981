/**
 * Simple UUID v4 function to create unique identifier based on {@link window.crypto}.
 * Please refer to {@see https://developer.mozilla.org/en-US/docs/Glossary/UUID} regarding
 * information around collision safety and implementation.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Crypto
 *
 * @param {string} [prefix='bron-'] - Optional prefix to prepend the unique identifier. Defaults to 'bron-'.
 * @returns {string} - Returns a unique identifier.
 */
export default function uuidv4(prefix = 'bron-') {
    return window.crypto?.randomUUID
        ? `${prefix}${window.crypto?.randomUUID()}`
        : `${prefix}${window.crypto?.getRandomValues(new Uint32Array(1))}-${window.crypto?.getRandomValues(new Uint32Array(1))}`;
}
