import React, { useEffect, useMemo, useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import pl from 'flatpickr/dist/l10n/pl';
import it from 'flatpickr/dist/l10n/it';
import de from 'flatpickr/dist/l10n/de';
import cs from 'flatpickr/dist/l10n/cs';
import es from 'flatpickr/dist/l10n/es';
import fr from 'flatpickr/dist/l10n/fr';
import nl from 'flatpickr/dist/l10n/nl';
import pt from 'flatpickr/dist/l10n/pt';
import ja from 'flatpickr/dist/l10n/ja';
import sk from 'flatpickr/dist/l10n/sk';
import classNames from 'classnames';
import { IMaskInput } from 'react-imask';
import IMask from 'imask';
import { FormFieldContext } from '../FormField/FormFieldContext';
import { Icon } from '../Icon/Icon';
/**
 * Additional inline styles for a disabled DatePicker button.
 * @type {{pointerEvents: string}}
 */
const disabledButtonStyle = { pointerEvents: 'none' };
/**
 * Regex to check against the native dateString format.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
 * @type {RegExp}
 */
const NATIVE_INPUT_DATE_REGEX = /\d{4}-\d{2}-\d{2}/;
/**
 * Pads one-digit date numbers with a leading zero.
 * @param {number} d - The date number to pad with a leading zero.
 * @return {string} - Returns of the passed date number.
 * @example
 *    padDate(1) → '01'
 */
const padDate = (d) => `${d < 10 ? d.toString().padStart(2, '0') : d}`;
/**
 * Extract a separator from a given dateFormat.
 * @param {string} dateFormat - The given dateFormat to extract the separator.
 * @return {string} - The date separator, usually a one-char string.
 * @example
 *    getSeparator('Y-m-d') → '-'
 */
const getSeparator = (dateFormat) => dateFormat.substring(1, 2);
/**
 * Create an {@link IMask.MaskedDate.pattern} input pattern.
 * @param {string} dateFormat - The given dateFormat string.
 * @return {IMask.MaskedDate.pattern} - Return a masked date pattern.
 *                                      Note the backticks: They are part of it!.
 * @example
 *    createPattern('Y-m-d') → 'Y-`m-`d'
 */
const createPattern = (dateFormat) => {
    const separator = getSeparator(dateFormat);
    return dateFormat.replaceAll(separator, `${separator}\``);
};
/**
 * Given a dateFormat string return an object with dateFormat as keys and their index
 * by splitting it via separator.
 * @param dateFormat
 * @return {{[string]: number}}
 * @example
 *    getDateTokenPosition('Y-m-d') → {'Y': 0, 'm': 1, 'd': 2}
 */
const getDateTokenPosition = (dateFormat) => {
    const separator = getSeparator(dateFormat);
    return dateFormat.split(separator).reduce((prev, curr, idx) => ({
        ...prev,
        [curr]: idx,
    }), {});
};
/**
 * Get an object of placeholder characters mapping on the dateFormat.
 * @param {string} dateFormat - The given dateFormat.
 * @param {string} [placeholder] - The given placeholder.
 * @return {{yearPlaceholderChar, dayPlaceholderChar, monthPlaceholderChar}|{yearPlaceholderChar: string, dayPlaceholderChar: string, monthPlaceholderChar: string}}
 * @example
 *    getPlaceholderChars('Y/m/d', 'a/b/c') →
 *    { yearPlaceholderChar: 'a', monthPlaceholderChar: 'b', dayPlaceholderChar: 'c' }
 */
const getPlaceholderChars = (dateFormat, placeholder) => {
    const separator = getSeparator(dateFormat);
    const { Y: yearPos, m: monthPos, d: dayPos } = getDateTokenPosition(dateFormat);
    if (placeholder) {
        const splittedPlaceholder = placeholder.split(separator);
        return {
            yearPlaceholderChar: splittedPlaceholder[yearPos],
            monthPlaceholderChar: splittedPlaceholder[monthPos],
            dayPlaceholderChar: splittedPlaceholder[dayPos],
        };
    }
    return {
        yearPlaceholderChar: 'Y',
        monthPlaceholderChar: 'm',
        dayPlaceholderChar: 'd',
    };
};
/**
 * The heart of date parsing. Is used by {@link Flatpickr} and also {@link IMask}
 * to parse the date input from a string to a valid date. The function has to make
 * assumptions whether the current input device is a plain `input[type=text]` or
 * a native `input[type=date]`.
 * If the passed date cannot be parsed directly, it is not in a valid native HTML5
 * date format `yyyy-mm-dd`. In such a case it is parsed accordingly the supplied {@link dateFormat}.
 *
 * @param {string} separator - The current date separator.
 * @param {number} yearPos - The position of the year in the date array.
 * @param {number} monthPos - The position of the month in the date array.
 * @param {number} dayPos - The position of the day in the date array.
 * @param {boolean} isNativeInput - If the input is a native or non-native input.
 * @param {string} [dateFormat] - The passed dateFormat.
 * @return {(function(*=): (string|null|Date))|*} - Returns a partially-applied function to parse dates.
 * @example
 *    parseDate('/', 0, 1, 2)('2000/01/01') → '2000-01-01'
 */
const parseDate = (separator, yearPos, monthPos, dayPos, isNativeInput, dateFormat) => (date) => {
    /**
     * Check if we are trying to parse the placeholder
     * and bail out if this is the case.
     */
    if (date.search(/[a-zA-Z]/g) >= 0) {
        return '';
    }
    /**
     * Check if the date can be split via the {@link separator}
     * and bail out if this is not the case.
     */
    if (!isNativeInput && !date.includes(separator)) {
        return '';
    }
    /**
     * Split the date into an array of its parts (year, month, day).
     * @type {*}
     */
    const yearMonthDay = date?.split(separator);
    /**
     * Check if the passed date has the native HTML5 date format.
     * @type {boolean}
     */
    const hasNativeDateFormat = NATIVE_INPUT_DATE_REGEX.test(date);
    /**
     * Constructed string that matches the date time string (a simplification of the ISO 8601 calendar date extended format). We are explicitly appending the daytime as 'T00:00:00' to have the same behavior as
     * constructing dates with individual date and time component values, e.g. new Date(2020, 3, 1).
     * @see https://developer.mozilla.org/en-US/docs/web/javascript/reference/global_objects/date/parse#date_time_string_format
     * @type {string}
     */
    const adaptiveDateString = `${yearMonthDay[yearPos]}-${yearMonthDay[monthPos]}-${yearMonthDay[dayPos]}T00:00:00`;
    /**
     * Check if the date origins from a non-native datepicker.
     */
    if (!isNativeInput) {
        const parsedDate = new Date(adaptiveDateString);
        if (isValidDateObject(parsedDate)) {
            return parsedDate;
        }
        // eslint-disable-next-line no-console
        console.error(`Bronson-React Picker: Your date (${date}) is invalid. Please check that it matches the dateFormat (${dateFormat}).`);
        return null;
    }
    /**
     * The date origins from a native  HTML5 datepicker `input[type=date]`.
     * If the date matches the hasNativeDateFormat (usually this happens on the first render)
     * we directly parse it, otherwise we use the {@link adaptiveDateString} to get
     * the date out of it.
     * @type {*|string}
     */
    const nativeDateString = hasNativeDateFormat ? date : adaptiveDateString;
    const parsedDate = new Date(nativeDateString);
    return isValidDateObject(parsedDate) ? parsedDate : null;
};
/**
 * Given a dateFormat, the partially applied function returns
 * an {@link IMask} date to string formatter function.
 * Only used when {@link maskedInput} is set to `true`.
 * @see https://imask.js.org/api/#maskedformat
 *
 * @param {string} dateFormat - The currently used {@link dateFormat}.
 * @return {function(*): string} - {@link IMask} date to string formatter function.
 * @example
 *    formatDate('Y-m-d')(1-12-2000) → '2000-12-01'
 */
const formatDate = (dateFormat) => (date) => {
    const day = padDate(date.getDate());
    const month = padDate(date.getMonth() + 1);
    const year = date.getFullYear();
    const { Y: yearPos, m: monthPos, d: dayPos } = getDateTokenPosition(dateFormat);
    const separator = getSeparator(dateFormat);
    const newDate = [];
    newDate[yearPos] = year;
    newDate[monthPos] = month;
    newDate[dayPos] = day;
    return newDate.join(separator);
};
/**
 * Set the correct locale for the calendar.
 * @param {('pl'|'de'|'it'|'cs'|'es'|'fr'|'nl'|'pt'|'ja'|'sk')} locale - The locale to set.
 * @return {CustomLocale|null}
 * @example
 *    setLocale('dk') → null
 */
const setLocale = (locale) => {
    switch (locale) {
        case 'pl':
            return pl.pl;
        case 'de':
            return de.de;
        case 'it':
            return it.it;
        case 'cs':
            return cs.cs;
        case 'es':
            return es.es;
        case 'fr':
            return fr.fr;
        case 'nl':
            return nl.nl;
        case 'pt':
            return pt.pt;
        case 'ja':
            return ja.ja;
        case 'sk':
            return sk.sk;
        default:
            return null;
    }
};
/**
 * Checks if the given variable is a valide date.
 * @param {*} v - The variable to check.
 * @return {boolean}
 * @example
 *    isValidDate(new Date('2000-01-01')) → true
 *    isValidDate(new Date('21')) → false
 *    isValidDate('2000-01-01') → false
 */
const isValidDateObject = (v) => v instanceof Date && !Number.isNaN(v.getDate());
/**
 * Naive check if the date matches the passed {@link dateFormat}.
 * @param {string} date
 * @param {string} dateFormat
 * @returns {boolean}
 * @example
 *    matchesDateFormat('2000-01-01', 'd.m.Y') → false
 *    matchesDateFormat('2000-01-01', 'Y.m.d') → false
 *    matchesDateFormat('2000-01-01', 'Y-m-d') → true
 */
export const matchesDateFormat = (date, dateFormat) => {
    /**
     * If no date was specified or if it contains letters (re iMask chars)
     * the date does not match.
     */
    if (!date || date.search(/[a-zA-Z]/g) >= 0) {
        return false;
    }
    // @ts-ignore @TODO: Fix to comply with expected type.
    const dateObj = globalThis?.flatpickr?.parseDate(date, dateFormat);
    const day = padDate(dateObj.getDate());
    const month = padDate(dateObj.getMonth() + 1);
    const year = dateObj.getFullYear().toString();
    const { Y: yearPos, m: monthPos, d: dayPos } = getDateTokenPosition(dateFormat);
    const separator = getSeparator(dateFormat);
    if (!date.includes(separator)) {
        return false;
    }
    const splitDate = date.split(separator);
    const hasDay = dayPos ? splitDate[dayPos] === day : true;
    const hasMonth = monthPos ? splitDate[monthPos] === month : true;
    const hasYear = yearPos ? splitDate[yearPos] === year : true;
    return hasDay && hasMonth && hasYear;
};
/**
 * Bronson Picker component.
 * Generic date and time picker component
 * to enable {@link DatePicker} and {@link TimePicker}.
 * @internal
 * @constructor
 */
export function Picker({ className, componentClassName, dateFormat = 'Y-m-d', disabled = false, disableDays = [], error = false, flatpickrOptions = {}, flatpickrProps, id, locale = 'de', maskedInput = true, mode = 'single', monthSelectorDropdown = false, name, noBackground = true, onBlur, onChange, onError, pickerClassName, placeholder, value: customValue, weekNumbers, testId, }) {
    /**
     * Track internal state via the wrapping {@link FormFieldContext}.
     * @type {object}
     */
    const context = React.useContext(FormFieldContext);
    /**
     * Hook to set active/inactive state on the Picker.
     */
    useEffect(() => {
        if (context?.setElementActive) {
            context.setElementActive(!!customValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customValue]);
    /**
     * Ref to the React flatpickr node.
     * @type {React.MutableRefObject<null>}
     */
    const flatpickrRef = useRef(null);
    /**
     * Ref to the masked input field.
     * Only utilized when {@link maskedInput} is set to `true`.
     * @type {React.MutableRefObject<null>}
     */
    const maskRef = useRef(null);
    /**
     * Track the internal date state.
     */
    const [date, setDate] = useState(customValue ?? '');
    /**
     * Track error states.
     */
    const [hasError, setHasError] = useState(false);
    /**
     * Track whether native/non-native input is used.
     * Is set via the {@link flatpickrRef}.
     */
    const [isMobileInput, setIsMobileInput] = useState(false);
    /**
     * Track the current {@link IMask.MaskedPattern.lazy} state.
     * @see https://imask.js.org/api/#maskedpatternlazy
     */
    const [isLazy, setIsLazy] = useState(true);
    /**
     * Memoize the instance’s {@link dateFormat} separator string, e.g. '-' or '/' etc..
     * @type {string}
     */
    const separator = useMemo(() => getSeparator(dateFormat), [dateFormat]);
    /**
     * Memoize the instance’s date part positions and extract them into variables.
     * @type {object}
     */
    const { Y: yearPos, m: monthPos, d: dayPos } = useMemo(() => getDateTokenPosition(dateFormat), [dateFormat]);
    /**
     * Memoize the instance’s date part placeholder and extract them into variables.
     * @type {object}
     */
    const { yearPlaceholderChar, monthPlaceholderChar, dayPlaceholderChar } = useMemo(() => getPlaceholderChars(dateFormat, placeholder), [dateFormat, placeholder]);
    /**
     * Memoize the instance’s date parser.
     */
    const mParseDate = useMemo(() => parseDate(separator, yearPos, monthPos, dayPos, isMobileInput, dateFormat), [separator, yearPos, monthPos, dayPos, isMobileInput, dateFormat]);
    /**
     * Memoize the instance’s date formatter.
     * @type {function(*): string}
     */
    const mFormatDate = useMemo(() => formatDate(dateFormat), [dateFormat]);
    /**
     * Set the current internal date state via a side effect.
     */
    useEffect(() => {
        setDate(customValue ?? '');
    }, [customValue]);
    /**
     * Set the current internal native/non-native input mode via a side effect.
     */
    useEffect(() => {
        setIsMobileInput(flatpickrRef?.current?.flatpickr?.isMobile);
    }, [flatpickrRef]);
    /**
     * Fixes a bug where the mobile input won’t reflect the state changes.
     * We just fix this for `[disabled]` here.
     * @see https://github.com/haoxins/react-flatpickr/issues/27
     * @see https://github.com/haoxins/react-flatpickr/issues/125
     */
    useEffect(() => {
        if (isMobileInput) {
            disabled
                ? flatpickrRef?.current?.flatpickr?.mobileInput?.setAttribute('disabled', '')
                : flatpickrRef?.current?.flatpickr?.mobileInput?.removeAttribute('disabled');
        }
    }, [disabled, isMobileInput]);
    const componentPickerWrapperClass = classNames('c-input', className).trim();
    const componentPickerClass = classNames('c-input__input', {
        'is-error': error,
    }, pickerClassName).trim();
    const componentPickerButtonClass = classNames('c-input__addon', {
        'c-input__addon--no-background': noBackground,
    }).trim();
    const componentPickerButtonIconClass = classNames('c-icon', `c-icon--[semantic-${flatpickrOptions?.enableTime ? 'clock' : 'calendar'}]`).trim();
    /**
     * Internal onValue function to handle date changes
     * and update the internal state.
     */
    const onValue = (value) => {
        /**
         * Set the lazy state to false and show the IMask if applicable.
         * Only used when {@link maskedInput} is set to `true`.
         */
        setIsLazy(false);
        /**
         * If we have a value we set the internal {@link date} state to it and call any external `onChange` function
         */
        if (value) {
            setDate(value);
        }
        /**
         * Synchronize the current {@link IMask.MaskedDate.value} from the input view.
         * Only used when {@link maskedInput} is set to `true`.
         * @see https://imask.js.org/api/#inputmaskupdatevalue
         */
        if (maskRef?.current?.maskRef?.updateValue) {
            maskRef?.current.maskRef.updateValue();
        }
        return value;
    };
    /**
     * Display a masked input via {@link IMask} when {@link maskedInput} is set to `true`
     * otherwise use a simple input element. For {@link TimePicker} we always disable inputMasks.
     * @return {JSX.Element}
     */
    const renderInput = () => maskedInput && mode === 'single' && !flatpickrOptions?.enableTime ? (React.createElement(IMaskInput, { className: componentPickerClass, name: name, ref: maskRef, "data-input": true, "data-testid": testId, mask: Date, overwrite: true, pattern: createPattern(dateFormat), format: mFormatDate, 
        // @ts-ignore @FIXME: Consolidate to `(value: string) => Date`.
        parse: mParseDate, blocks: {
            d: { mask: IMask.MaskedRange, placeholderChar: dayPlaceholderChar, from: 1, to: 31, maxLength: 2 },
            m: { mask: IMask.MaskedRange, placeholderChar: monthPlaceholderChar, from: 1, to: 12, maxLength: 2 },
            Y: { mask: IMask.MaskedRange, placeholderChar: yearPlaceholderChar, from: 1, to: 2999 },
        }, autofix: false, lazy: isLazy, disabled: disabled, id: id })) : (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    React.createElement("input", { "data-testid": testId, name: name, className: componentPickerClass, placeholder: placeholder, disabled: disabled, "data-input": true, id: id }));
    /**
     * Render the DatePicker calendar icon.
     * @return {JSX.Element}
     */
    const renderIcon = () => {
        return React.createElement(Icon, { className: componentPickerButtonIconClass, viaCss: true });
    };
    /**
     * Render the DatePicker button.
     * @return {JSX.Element}
     */
    const renderButton = () => {
        return (React.createElement("button", { className: componentPickerButtonClass, type: "button", tabIndex: -1, "aria-label": "toggle datepicker", "data-toggle": true, style: disabled ? disabledButtonStyle : undefined }, renderIcon()));
    };
    return (React.createElement("div", { className: componentClassName },
        React.createElement(Flatpickr, { className: componentPickerWrapperClass, ref: flatpickrRef, ...flatpickrProps, value: date, 
            /**
             * Hook
             * @see node_modules/flatpickr/dist/typings.d.ts
             */
            onChange: (dates, currentDateString) => {
                if (!hasError) {
                    const dateString = onValue(currentDateString);
                    if (dateString) {
                        onChange?.(dateString);
                    }
                    /**
                     * Handle active/inactive state on the Picker.
                     */
                    context?.setElementActive?.(!!dateString);
                }
            }, 
            /**
             * The `onBlur` hook gets the current value from the input,
             * as {@link Flatpickr} does not support `blur` hooks itself.
             */
            onBlur: (event) => {
                if (!hasError) {
                    /**
                     * Prevent blurs when the calendar popup is still open.
                     */
                    if (!flatpickrRef?.current?.flatpickr?.isOpen) {
                        const inputValue = event.target.value;
                        const dateString = onValue(inputValue);
                        /**
                         * Check if the input is a native (mobile) HTML5 input.
                         * If so, we need to convert the W3C standard date format
                         * to the one that was passed for {@link dateFormat}.
                         */
                        if (isMobileInput) {
                            const nativeDate = new Date(dateString);
                            if (isValidDateObject(nativeDate)) {
                                /**
                                 * Format from `YYYY-MM-DD` to {@link dateFormat}.
                                 */
                                const formattedDate = flatpickrRef?.current?.flatpickr?.formatDate(nativeDate, dateFormat);
                                onBlur?.(formattedDate);
                            }
                            else {
                                onError?.(`Bronson-React Picker: Native date picker provided an invalid date (${dateString})`);
                            }
                        }
                        else {
                            onBlur?.(dateString);
                        }
                        setIsLazy(true);
                    }
                }
            }, onValueUpdate: () => {
                if (maskRef?.current?.maskRef?.updateValue) {
                    maskRef?.current.maskRef.updateValue();
                }
            }, onClick: () => setIsLazy(false), options: {
                /**
                 * Callback for {@link Flatpickr} internal error handler.
                 * @param {Error} err - Internal Flatpickr error.
                 */
                errorHandler: (err) => {
                    /**
                     * Check if the error has a message.
                     * Set the Picker {@link hasError} state to `true`. This prevents
                     * other callback from fire due to the async value reset further down.
                     * Only recover for date inputs but not when {@link flatpickrOptions?.enableTime}
                     * is set.
                     */
                    if (err?.message && !flatpickrOptions?.enableTime) {
                        /**
                         * Prevent Flatpickr from throwing an error when the initial mask is displayed.
                         */
                        if (maskRef.current?.maskRef?.masked?.value?.includes(yearPlaceholderChar) &&
                            maskRef.current?.maskRef?.masked?.value?.includes(monthPlaceholderChar) &&
                            maskRef.current?.maskRef?.masked?.value?.includes(dayPlaceholderChar)) {
                            setHasError(true);
                            setTimeout(() => {
                                setHasError(false);
                            }, 0);
                            if (!hasError) {
                                onChange?.('');
                            }
                            return;
                        }
                        /**
                         * Call the {@link onError} callback.
                         */
                        onError?.(err?.message);
                        /**
                         * IF a Flatpickr instance is present, try to recover the last valid value.
                         */
                        if (flatpickrRef?.current?.flatpickr) {
                            setHasError(true);
                            setTimeout(() => {
                                /**
                                 * Only recover valid values, otherwise just ignore.
                                 */
                                if (matchesDateFormat(date, dateFormat)) {
                                    if (maskRef.current?.maskRef?.updateValue) {
                                        maskRef.current.maskRef.updateValue();
                                    }
                                    flatpickrRef?.current?.flatpickr?.setDate(date);
                                }
                                else if (mode === 'range' || mode === 'multiple') {
                                    flatpickrRef?.current?.flatpickr?.setDate(date);
                                }
                                setHasError(false);
                            }, 0);
                        }
                    }
                },
                wrap: true,
                allowInput: true,
                mode,
                locale: setLocale(locale),
                /**
                 * Disable custom date parsing for {@link TimePicker}.
                 * @param dateStr
                 * @return {null|string|Date}
                 */
                parseDate: flatpickrOptions?.enableTime ? null : (dateStr) => mParseDate(dateStr),
                dateFormat,
                disable: disableDays,
                nextArrow: '<i class="c-icon c-icon--[semantic-forward]" aria-hidden="true" role="img"></i>',
                prevArrow: '<i class="c-icon c-icon--[semantic-back]" aria-hidden="true" role="img"></i>',
                monthSelectorType: monthSelectorDropdown ? 'dropdown' : 'static',
                weekNumbers,
                ...flatpickrOptions,
            } },
            renderInput(),
            renderButton())));
}
