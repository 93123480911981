import React from 'react';
import classNames from 'classnames';
/**
 * Bronson Select component.
 *
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-select.html
 *
 * @constructor
 */
export function Select({ error, disabled, name, id, testId, children, className, success, ...otherProps }) {
    const divClassNameList = classNames({
        'is-disabled': disabled,
    }, 'c-input c-input--select', className).trim();
    const selectClassNameList = classNames('c-input__input', {
        'is-error': error,
        'is-success': success,
    }).trim();
    return (React.createElement("div", { className: divClassNameList },
        React.createElement("select", { id: id, className: selectClassNameList, name: name, disabled: disabled, "aria-invalid": error ? true : undefined, "data-testid": testId, ...otherProps }, children)));
}
/**
 * Bronson SelectItem component (nested).
 *
 * @constructor
 */
function SelectItem({ children, value, ...otherProps }) {
    return (React.createElement("option", { ...otherProps, value: value }, children));
}
SelectItem.displayName = 'Select.Item';
Select.Item = SelectItem;
