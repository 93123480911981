import React from 'react';
import classNames from 'classnames';
/**
 * The Callout component displays a prominent message with a call to action.
 *
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-callout.html
 *
 * @constructor
 */
export function Callout({ children, className, elementType = 'article', headingLevel = 4, media, title, testId, ...otherProps }) {
    const classNameList = classNames('c-callout', className).trim();
    const ComponentElement = `${elementType}`;
    const HeadingElement = `h${headingLevel}`;
    const renderMedia = () => {
        if (media) {
            const imageClassName = classNames('c-callout__image', media?.className).trim();
            return (React.createElement("div", { className: "c-callout__media" },
                React.createElement("img", { ...media, className: imageClassName })));
        }
        return null;
    };
    return (React.createElement(ComponentElement, { className: classNameList, "data-theme": "brand", "data-testid": testId, ...otherProps },
        renderMedia(),
        React.createElement(HeadingElement, { className: "c-callout__title" }, title),
        children));
}
