import React from 'react';
import classNames from 'classnames';
import { Button } from '../Button/Button';
export var TableButtonSorting;
(function (TableButtonSorting) {
    TableButtonSorting["ASC"] = "asc";
    TableButtonSorting["DESC"] = "desc";
})(TableButtonSorting || (TableButtonSorting = {}));
export function TableButton({ children, className, icon, sort, testId, ...otherProps }) {
    const sortAsc = sort === TableButtonSorting.ASC;
    const sortDesc = sort === TableButtonSorting.DESC;
    const componentListClass = classNames({
        'c-table__btn': true,
        'c-table__btn-sort': sortAsc || sortDesc,
        'is-sort-asc': sortAsc,
        'is-sort-desc': sortDesc,
    }, className).trim();
    const sortProps = sort
        ? {
            'data-sort-order': sortAsc ? 'asc' : 'desc',
            iconViaCss: true,
        }
        : {};
    const nonSortProps = sort
        ? {}
        : {
            secondary: !!icon,
            small: !!icon,
            icon,
        };
    return (React.createElement(Button, { className: componentListClass, ...sortProps, ...nonSortProps, type: "button", "data-testid": testId, ...otherProps }, children));
}
