import uuid from '../utils/uuidv4';
export const SCROLL_OBSERVER_EVENT = 'ScrollObserverEvent';
export const SCROLL_OBSERVER_SKIP_OBSERVE_ATTR = 'data-scroll-observer-skip';
/**
 * The ScrollObserverCore handles all internal class/intersection calculations.
 * @type {ScrollObserverCore}
 */
export const ScrollObserverCore = Object.seal({
    ___uuid: uuid(),
    lastScrollTop: null,
    mostVisibleEntry: null,
    classes: {
        inViewportClass: 'has-target-inside-view',
        outsideViewportClass: 'has-target-outside-view',
        aboveEnterViewportClass: 'has-target-enter-above-view',
        aboveLeaveViewportClass: 'has-target-leave-above-view',
        belowEnterViewportClass: 'has-target-enter-below-view',
        belowLeaveViewportClass: 'has-target-leave-below-view',
        mostVisibleViewportAttribute: 'data-target-most-visible-view',
    },
    scrollObserverOptions: {
        allowMultiple: false,
        /**
         * Setting proper defaults if data attributes are nullish.
         * @see https://w3c.github.io/IntersectionObserver/#dictdef-intersectionobserverinit
         */
        root: null,
        rootMargin: '0px',
        threshold: 0,
    },
    observeEntries(element, opts, onMatch) {
        return (entries) => {
            entries.forEach((entry) => {
                const { boundingClientRect: { top }, intersectionRect: { height }, } = entry;
                entry.target.__intersectionHeight = height;
                if (!element.hasAttribute(SCROLL_OBSERVER_SKIP_OBSERVE_ATTR)) {
                    if (entry.isIntersecting) {
                        element.classList.remove(opts.classes.outsideViewportClass);
                        element.classList.add(opts.classes.inViewportClass);
                        if (ScrollObserverCore.lastScrollTop ?? top < 0) {
                            element.classList.add(opts.classes.belowEnterViewportClass);
                            element.classList.remove(opts.classes.belowLeaveViewportClass);
                            element.classList.remove(opts.classes.aboveEnterViewportClass);
                        }
                        else {
                            element.classList.add(opts.classes.aboveEnterViewportClass);
                            element.classList.remove(opts.classes.aboveLeaveViewportClass);
                            element.classList.remove(opts.classes.belowEnterViewportClass);
                        }
                    }
                    else {
                        element.classList.add(opts.classes.outsideViewportClass);
                        element.classList.remove(opts.classes.inViewportClass);
                        if (ScrollObserverCore.lastScrollTop ?? top < 0) {
                            element.classList.add(opts.classes.aboveLeaveViewportClass);
                            element.classList.remove(opts.classes.aboveEnterViewportClass);
                            element.classList.remove(opts.classes.belowEnterViewportClass);
                        }
                        else {
                            element.classList.add(opts.classes.belowLeaveViewportClass);
                            element.classList.remove(opts.classes.belowEnterViewportClass);
                            element.classList.remove(opts.classes.aboveEnterViewportClass);
                        }
                    }
                }
                ScrollObserverCore.lastScrollTop = top;
            });
            if (opts.intersectionObserverOpts.allowMultiple) {
                ScrollObserverCore.mostVisibleEntry = entries.reduce((prev, curr) => {
                    // @ts-expect-error TS(2531): Object is possibly 'null'.
                    // eslint-disable-next-line no-underscore-dangle
                    return curr.target.__intersectionHeight > (prev?.target?.__intersectionHeight ?? 0)
                        ? curr
                        : prev;
                }, null);
                if (ScrollObserverCore.mostVisibleEntry) {
                    element.setAttribute(ScrollObserverCore.classes.mostVisibleViewportAttribute, `#${ScrollObserverCore.mostVisibleEntry?.target?.id}`);
                }
            }
            if (!element.hasAttribute(SCROLL_OBSERVER_SKIP_OBSERVE_ATTR)) {
                onMatch({
                    intersectionObserverEntries: entries,
                    mostVisibleEntry: ScrollObserverCore.mostVisibleEntry,
                });
            }
        };
    },
});
