import { useState } from 'react';
/**
 * Hook used to apply conditional rendering on hidden elements.
 * Use in case performance issues occur, e.g. when hidden elements take a huge amount in the DOM or
 * have side effects like fetching data.
 * Internally it manages a rendered state for a list of items.
 *
 * Use isRendered to conditional render the element with hidden/aria-hidden.
 * Call setRendered when the element turns visible, typically hidden=false.
 *
 * @param {*} lazy when false, it will not check the rendered state but returns always true (-> eager rendering)
 * @returns object as { isRendered, setRendered } with isRendered returning the current rendered state and
 *                 setRendered to set the rendered state for an item
 */
export const useLazyRender = (lazy = true) => {
    const [rendered, setRendered] = useState([]);
    return {
        isRendered: (id) => !lazy || rendered.indexOf(id) >= 0,
        setRendered: (id) => {
            if (rendered.findIndex((entry) => entry === id) < 0) {
                setRendered([...rendered, id]);
            }
        },
    };
};
