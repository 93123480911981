import React, { forwardRef, useEffect, } from 'react';
import classNames from 'classnames';
import { FormFieldContext } from '../FormField/FormFieldContext';
import { Icon } from '../Icon/Icon';
/**
 * Bronson Input component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-input.html
 * @constructor
 */
export const Input = forwardRef(({ addonClassName, addonClickHandler, addonElement = 'span', addonIcon, addonSpinner, addonText, addonType, className, defaultValue, divProps, error, ghost, id, modifierStates, noBackground, onChange, reversed, stateIcon, success, type, testId, ...otherProps }, ref) => {
    const CustomElement = `${addonElement}`;
    /**
     * Track internal state via the wrapping {@link FormFieldContext}.
     * @type {object}
     */
    const context = React.useContext(FormFieldContext);
    /**
     * Hook to set active/inactive states on the {@link Input}.
     */
    useEffect(() => {
        if (context?.setElementActive) {
            context.setElementActive(!!defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);
    const divClassNameList = classNames('c-input', {
        [`c-input--${type} `]: type,
        // Main class modifier convenience prop extension.
        'c-input--ghost': ghost,
        'c-input--reversed ': reversed, // Convenience prop from Bronson variants.
    }, className).trim();
    const inputClassNameList = classNames('c-input__input', {
        'is-success': success,
        'is-error': error,
        [`${modifierStates} `]: modifierStates,
    }).trim();
    function renderIfStateIcon() {
        if (stateIcon) {
            return React.createElement(Icon, { className: "c-input__state-icon", viaCss: true });
        }
        return null;
    }
    const spanClassNameList = classNames('c-input__addon', {
        'c-input__addon--no-background': noBackground, // Convenience prop from Bronson variants.
    }, addonClassName).trim();
    function renderIfAddon() {
        if (addonIcon || addonSpinner || addonText) {
            const addon = addonSpinner ? addonSpinner : addonText;
            return (React.createElement(CustomElement, { className: spanClassNameList, 
                /**
                 * Render the element type if set, but always set it for `button`.
                 */
                type: addonType ?? addonElement === 'button' ? addonElement : undefined, "aria-label": addonIcon || addonSpinner ? addonText : undefined, onClick: addonElement === 'button' ? addonClickHandler : null }, addonIcon ? (React.createElement(React.Fragment, null,
                React.createElement(Icon, { name: addonIcon }))) : (addon)));
        }
        return null;
    }
    return (React.createElement("div", { className: divClassNameList, ...divProps },
        React.createElement("input", { defaultValue: defaultValue, id: id, className: inputClassNameList, type: `${type || 'text'}`, "aria-invalid": error ? true : undefined, "aria-describedby": error ? `error-${id}` : undefined, "data-testid": testId, ref: ref, onChange: (event) => [
                onChange && onChange(event),
                context.setElementActive && context.setElementActive(!!event.target.value),
            ], ...otherProps }),
        renderIfStateIcon(),
        renderIfAddon()));
});
