import React from 'react';
import classNames from 'classnames';
import { SectionHeading } from '../SectionHeading/SectionHeading';
/**
 * The ProductSearchForm component is meant to be used as
 * an initial search method, that leads into a search results page.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-product-search-form.html
 * @constructor
 */
export function ProductSearchForm({ children, className, title, testId, ...otherProps }) {
    const classNameList = classNames('c-product-search-form', className).trim();
    return (React.createElement("article", { className: classNameList, "data-testid": testId, ...otherProps },
        React.createElement(SectionHeading, { center: true, level: 2, className: "c-product-search-form__heading" }, title),
        children));
}
/**
 * The ProductSearchFormActionButtons component is internally used to wrap
 * the action buttons and display it inside the {@link ProductSearchForm}.
 * @constructor
 */
function ProductSearchFormActionButtons({ className, filterButton, resetButton, submitButton, testId, ...otherProps }) {
    const classNameList = classNames('c-product-search-form__action-buttons', className).trim();
    return (React.createElement("div", { className: classNameList, "data-testid": testId, ...otherProps },
        filterButton,
        React.createElement("div", { className: "c-product-search-form__button-container" },
            resetButton,
            submitButton)));
}
ProductSearchFormActionButtons.displayName = 'ProductSearchForm.ActionButtons';
ProductSearchForm.ActionButtons = ProductSearchFormActionButtons;
