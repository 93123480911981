import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { InfoIcon } from '../InfoIcon/InfoIcon';
import { useLazyRender } from '../../features/useLazyRender';
const AccordionContext = React.createContext(null);
/**
 * Bronson Accordion component.
 *
 * https://bronson.vwfs.tools/default/components/detail/bronson-accordion.html
 *
 * @constructor
 */
export function Accordion({ children, className, headerSplit, itemKeyProp, lazyRender = false, multipleOpen, onChange, withBackground, testId, ...otherProps }) {
    /**
     * Construct a default state for the context provider.
     * @type {Array<Exclude<any, boolean | null | undefined>>}
     */
    const defaultOpen = React.Children.map(children, (child) => {
        if (child?.props?.defaultOpen) {
            return child?.props?.[child?.props?.itemKeyProp] ?? child?.props?.title;
        }
    }) ?? [];
    const [open, setOpen] = useState(multipleOpen ? defaultOpen : defaultOpen.slice(0, 1));
    const { isRendered, setRendered } = useLazyRender(lazyRender);
    const isOpen = (itemKey) => {
        return open.indexOf(itemKey) >= 0;
    };
    const setOpenAndNotify = (items) => {
        setOpen(items);
        onChange && onChange(items);
    };
    const updateOpen = (itemKey, shouldOpen) => {
        const index = open.findIndex((entry) => entry === itemKey);
        if (multipleOpen) {
            // is not yet open + shouldOpen -> open
            if (index < 0 && shouldOpen) {
                open.push(itemKey);
                setOpenAndNotify([...open]);
            }
            else if (index >= 0 && !shouldOpen) {
                // is open + !shouldOpen -> close
                open.splice(index, 1);
                setOpenAndNotify([...open]);
            }
        }
        else if (shouldOpen) {
            // is not open -> open
            setOpenAndNotify([itemKey]);
        }
        else {
            // is open -> close
            setOpenAndNotify([]);
        }
        if (shouldOpen) {
            setRendered(itemKey);
        }
    };
    /*
     * Change open state for the given item (ref) without the need to know the current state.
     */
    const toggleOpen = (itemKey) => {
        updateOpen(itemKey, !isOpen(itemKey));
    };
    const divClassNameList = classNames('c-accordion', {
        // Main class modifier convenience prop extension.
        'c-accordion--bg-panel': withBackground,
        'c-accordion--header-split': headerSplit, // Convenience prop from Bronson variants.
    }, className).trim();
    return (React.createElement("div", { ...otherProps, "data-testid": testId, className: divClassNameList },
        React.createElement(AccordionContext.Provider, { value: { isOpen, toggleOpen, updateOpen, itemKeyProp, isRendered } }, children)));
}
/**
 * Bronson AccordionItem component.
 * @internal
 * @returns {JSX.Element} - The Accordion component.
 * @constructor
 */
export function AccordionItem(props) {
    const { children, className, contentClassName, defaultOpen, icon, subtitle, title, titleSplit, tooltip, testId, ...otherProps } = props;
    /**
     * Track onAnimationEnd expanded/collapsed state.
     */
    const [isExpanded, setIsExpanded] = useState(defaultOpen ?? false);
    const [isCollapsed, setIsCollapsed] = useState(!defaultOpen ?? true);
    const context = useContext(AccordionContext);
    const itemKey = context?.itemKeyProp ? props[context.itemKeyProp] : title;
    const childDivClassNameList = classNames('c-accordion__header', {
        'is-active': context?.isOpen(itemKey),
    }, className).trim();
    const contentDivClassNameList = classNames('c-accordion__content', contentClassName).trim();
    const panelClassNameList = classNames('c-accordion__panel', {
        /**
         * Async because triggered via onTransitionEnd.
         * Make sure the async state classes are just set according
         * to the {@link context?.isOpen} state. `.is-expanded` is only applied
         * after expanding the panel, and is immediately removed at the beginning of collapsing.
         */
        'is-expanded': context?.isOpen(itemKey) && isExpanded,
        'is-collapsed': !context?.isOpen(itemKey) && isCollapsed,
    }).trim();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: childDivClassNameList, onClick: () => {
                context?.toggleOpen(itemKey);
                /**
                 * Remove the expanded state directly onclick when the accordion is closing.
                 */
                if (!context?.isOpen(itemKey)) {
                    setIsExpanded(false);
                }
                else if (context?.isOpen(itemKey)) {
                    setIsCollapsed(false);
                }
            }, "data-testid": testId, ...otherProps },
            React.createElement("span", { className: "c-accordion__title-icon" }),
            React.createElement("h3", { className: "c-accordion__title" },
                React.createElement("span", { className: "c-accordion__title-label", "aria-expanded": context?.isOpen(itemKey), role: "button", tabIndex: 0 },
                    React.createElement("span", { className: "c-accordion__title-label-text" },
                        title,
                        icon && (React.createElement(InfoIcon, { className: "c-accordion__title-info-icon", icon: icon }, tooltip))),
                    titleSplit && React.createElement("span", { className: "c-accordion__title-label-text" }, titleSplit))),
            subtitle && React.createElement("p", { className: "c-accordion__subtitle" }, subtitle)),
        context?.isRendered(itemKey) && (
        /**
         * Need to pass `[inert=""]` as React currently does not support HTMLElement.inert mapping.
         * @see https://github.com/facebook/react/pull/24730
         * @TODO: Remove once React supports direct `[inert]` usage.
         * @see BRON-11871
         */
        React.createElement("div", { className: panelClassNameList, "aria-hidden": !context?.isOpen(itemKey), 
            // @ts-ignore @TODO: Remove once React supports inerts, @see BRON-11871.
            inert: context?.isOpen(itemKey) ? null : '', onTransitionEnd: (event) => {
                /**
                 * Prevents React from resetting its properties.
                 * Deprecated: Only relevant for React 16 and earlier. As of React 17,
                 * `e.persist()` doesn’t do anything because the `SyntheticEvent` is no longer pooled.
                 * @see https://reactjs.org/docs/events.html
                 * @DEPRECATED: Remove in v10.
                 */
                event.persist();
                const panel = event?.target;
                /**
                 * Add class when transition has finished as trigger that can
                 * be used in CSS.
                 */
                if (panel?.hasAttribute('aria-hidden')) {
                    if (panel?.matches('[aria-hidden="false"]')) {
                        setIsExpanded(true);
                    }
                    else {
                        setIsCollapsed(true);
                    }
                }
            } },
            React.createElement("div", { className: contentDivClassNameList }, children)))));
}
Accordion.Item = AccordionItem;
AccordionItem.displayName = 'Accordion.Item';
