import { useEffect, useRef } from 'react';
export const gridElementId = 'float-grid';
export const gridElementClass = 'o-float-grid';
export const gridAreaClass = 'o-float-grid__area';
export const gridAreaDataAttribute = 'data-float-grid-area';
export const gridAreaCssCustomProperty = '--float-grid-area';
/**
 * Available areas for placing elements.
 * @type {string[]}
 */
export const floatGridAreas = [
    'top',
    'bottom',
    'top-left',
    'top-center',
    'top-right',
    'center-left',
    'center-center',
    'center-right',
    'bottom-left',
    'bottom-center',
    'bottom-right',
];
export const getFloatGridCustomProp = (ref) => ref && getComputedStyle(ref).getPropertyValue(gridAreaCssCustomProperty).trim();
/**
 * Create the floatGrid root element.
 * @returns {HTMLElement} - The floatGrid root element with all areas appended.
 */
function createFloatGridRootElement(id = gridElementId) {
    const rootContainer = document.createElement('div');
    rootContainer.setAttribute('id', id);
    rootContainer.className = gridElementClass;
    /**
     * Iterate over the {@link floatGridAreas} and create a child element for each.
     */
    floatGridAreas.forEach((area) => {
        rootContainer.insertAdjacentHTML('afterbegin', `<div class="${gridAreaClass}" ${gridAreaDataAttribute}="${area}"></div>`);
    });
    return rootContainer;
}
/**
 * Appends element as the firstChild’s previous element sibling of body.
 * @param {HTMLElement} floatGridContainerElement - The float grid container element.
 */
function addFloatGridContainerElement(floatGridContainerElement) {
    document.body.insertBefore(floatGridContainerElement, document.body.firstElementChild);
}
/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 * @param {String} area - The id of the target container, e.g. `modal` or `spotlight`.
 * @returns {HTMLElement} - The DOM node to use as the Portal target.
 * @example
 *      const target = useFloatGrid(id, [id]);
 *      return createPortal(children, target);
 */
function useFloatGrid(area) {
    const rootElemRef = useRef(null);
    useEffect(() => {
        /**
         * Bail out if no area was passed via hoop arguments
         */
        if (!area) {
            return;
        }
        // Look for existing target DOM element to append to.
        const existingParent = document.querySelector(`#${gridElementId}`);
        // Parent is either a new root or the existing DOM element.
        const parentElem = existingParent || createFloatGridRootElement(gridElementId);
        // If there is no existing DOM element, add a new one.
        if (!existingParent) {
            addFloatGridContainerElement(parentElem);
        }
        const childRef = rootElemRef.current;
        /**
         * If there is no firstRootChild and area is not explicitly set to the string `false`
         * (this is a Bronson setting to disable area placement), move the element to the respective area.
         */
        if (childRef && area && area !== 'false') {
            const floatGridAreaElement = parentElem.querySelector(`[${gridAreaDataAttribute}=${area}]`);
            if (floatGridAreaElement) {
                // Move the element to target Float Grid area.
                floatGridAreaElement.appendChild(childRef);
            }
            else {
                // Throw error if the area is not found.
                throw new TypeError(`Float Grid area ${floatGridAreaElement} does not exist.`);
            }
            if (!floatGridAreaElement?.childElementCount) {
                // eslint-disable-next-line consistent-return
                return floatGridAreaElement?.remove();
            }
        }
        // eslint-disable-next-line consistent-return
        return () => {
            childRef?.remove();
        };
    }, [area, rootElemRef]);
    /**
     * It’s important we evaluate this lazily:
     * - We need first render to contain the DOM element, so it shouldn’t happen
     *   in `useEffect`. We would normally put this in the `constructor()`.
     * - We can’t do `const rootElemRef = useRef(document.createElement('div))`,
     *   since this will run every single render (that’s a lot).
     * - We want the ref to consistently point to the same DOM element and only
     *   ever run once.
     * @see https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
     */
    function getRootElem() {
        if (!rootElemRef.current) {
            rootElemRef.current = document.createElement('div');
            /**
             * This artificial wrap should not have any display semantics.
             */
            rootElemRef?.current?.style.setProperty('display', 'contents');
        }
        return rootElemRef.current;
    }
    return getRootElem();
}
export default useFloatGrid;
