import React from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
/**
 * Bronson button component.
 *
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-buttons.html
 *
 * @constructor
 */
export function Button({ action, ariaExpanded, ariaLabel, children, className, disabled, element = 'button', full, hiddenLabel, icon, iconClassName, iconReversed, iconViaCss, incognito, inContainer, link, onClick, round, secondary, simple, small, title, truncate, testId, ...otherProps }) {
    /**
     * This assigns the `element` (`button-element` in Bronson) prop to a variable
     * which is in PascalCase, because custom component tags names must start with
     * an upperCase letter as lower-case tags are reserved for HTML.
     * @see https://reactjs.org/docs/jsx-in-depth.html#html-tags-vs.-react-components
     */
    // @ts-ignore
    const CustomElement = typeof element === 'string' ? `${element}` : element;
    const buttonClassNameList = classNames('c-btn', {
        'c-btn--icon': icon && !link,
        // Automatically assign the icon-only class when icon is set and no label is assigned.
        'c-btn--icon-only': icon && !link && (!children || hiddenLabel),
        // Convenience props from Bronson variants.
        'c-btn--secondary': secondary,
        'c-btn--action': action,
        'c-btn--full': full,
        'c-btn--incognito': incognito,
        'c-btn--link': link,
        'c-btn--small': small,
        'c-btn--round': round,
        'c-btn--simple': simple,
        'c-btn--truncate': truncate,
        // Do not apply reverse logic on links. They are reversed automatically.
        'c-btn--reversed': icon && !link && iconReversed,
        /**
         * Convenience prop when Button is used inside {@link ButtonContainer}.
         * @deprecated Will be removed in v10.
         */
        'o-button-container__button': inContainer,
    }, className).trim();
    const iClassNameList = classNames('c-btn__icon', iconClassName).trim();
    /**
     * Throw an accessibility warning when an icon-only is used without a proper label.
     */
    if ((icon || iconViaCss) && !children && !ariaLabel) {
        console.warn('Bronson-React: <Button> with `icon` or `iconViaCSS` set requires the `ariaLabel` prop.');
    }
    function renderIfButtonIcon() {
        if (icon || iconViaCss) {
            return React.createElement(Icon, { className: iClassNameList, name: icon, viaCss: iconViaCss });
        }
        return null;
    }
    const spanClassNameList = classNames({
        'c-btn__text ': true,
        'u-visually-hidden ': hiddenLabel,
    }).trim();
    function renderIfButtonLabel() {
        if (children) {
            return React.createElement("span", { className: spanClassNameList }, children);
        }
        return null;
    }
    return (React.createElement(CustomElement, { "aria-disabled": disabled && 'true', "aria-expanded": ariaExpanded, "aria-label": ariaLabel, className: buttonClassNameList, "data-testid": testId, disabled: disabled, onClick: onClick, title: title, ...otherProps },
        React.createElement(React.Fragment, null,
            renderIfButtonLabel(),
            renderIfButtonIcon())));
}
