import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Button } from '../Button/Button';
import { Heading } from '../Heading/Heading';
import { Paragraph } from '../Paragraph/Paragraph';
import { Icon } from '../Icon/Icon';
import uuidv4 from '../../utils/uuidv4';
/**
 * Bronson Modal component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-modal--modal.html
 * @constructor
 */
export function Modal({ asPortal, buttonCancelLabel, buttonCancelText, buttonCloseLabel, buttonConfirmLabel, buttonConfirmText, buttonConfirmType, center, children, className, closeAny, confirmationDisabled, hideCloseButton, hideTitle, id, large, left, onCancel, onClickOutside, onClose, onConfirm, portalContainer, shown, status, stretch, title, testId, ...otherProps }) {
    const MODAL_TITLE_ID = uuidv4('bron-modal-title-');
    const modalWrapper = useRef(null);
    const componentModalClass = classNames('c-modal', {
        'c-modal--left': left,
        'c-modal--center': center,
        'c-modal--large': large,
        'c-modal--stretch': stretch,
        [`c-modal--${status}`]: status,
    }, className).trim();
    const componentModalTitleWrapperClass = classNames('c-modal__title__wrapper', {
        'u-visually-hidden': hideTitle,
    }).trim();
    const onClick = (e) => {
        if (modalWrapper.current !== e.currentTarget || closeAny) {
            onClose?.();
        }
        e.stopPropagation();
    };
    function renderChildren() {
        if (typeof children === 'string') {
            return React.createElement(Paragraph, null, children);
        }
        return children;
    }
    function renderFooter() {
        /**
         * Conditionally render Modal footer.
         */
        if (!(buttonCancelText || buttonConfirmText)) {
            return null;
        }
        return (React.createElement("div", { className: "c-modal__footer" },
            React.createElement("div", { className: "c-modal__actions" },
                buttonCancelText && (React.createElement("div", { className: "c-modal__actions__item" },
                    React.createElement(Button, { type: "button", onClick: onCancel, secondary: true, "aria-label": buttonCancelLabel }, buttonCancelText))),
                buttonConfirmText && (React.createElement("div", { className: "c-modal__actions__item" },
                    React.createElement(Button, { type: buttonConfirmType, onClick: closeAny ? onClick : onConfirm, "aria-label": buttonConfirmLabel, disabled: confirmationDisabled }, buttonConfirmText))))));
    }
    function onKeyDown(e) {
        if (e.keyCode === 27) {
            onClose?.();
        }
    }
    if (shown) {
        document.addEventListener('keydown', onKeyDown, { capture: false });
    }
    else {
        document.removeEventListener('keydown', onKeyDown, { capture: false });
    }
    const modal = (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    React.createElement("div", { "data-testid": testId, className: componentModalClass, "aria-hidden": !shown, id: id, onClick: closeAny ? onClick : onClickOutside, ...otherProps },
        React.createElement("div", { className: "c-modal__overlay", tabIndex: -1 }),
        React.createElement("div", { className: "c-modal__wrapper", role: "dialog", "aria-labelledby": title ? MODAL_TITLE_ID : undefined, onClick: onClick, ref: modalWrapper },
            React.createElement("header", { className: "c-modal__header" },
                title && (React.createElement("div", { className: componentModalTitleWrapperClass },
                    status && React.createElement(Icon, { className: "c-modal__status-icon", viaCss: true }),
                    React.createElement(Heading, { level: 2, className: "c-modal__title", id: MODAL_TITLE_ID }, title))),
                !hideCloseButton && (React.createElement("button", { type: "button", className: "c-modal__close-btn", onClick: onClick, "aria-label": buttonCloseLabel, title: buttonCloseLabel }))),
            React.createElement("div", { className: "c-modal__content", role: "document" }, renderChildren()),
            renderFooter())));
    return asPortal ? ReactDOM.createPortal(modal, portalContainer || document.body) : modal;
}
