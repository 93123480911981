import React, { isValidElement, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import getTextContent from '../../utils/getTextContent';
import { Video } from '../Video/Video';
import { PlayButton } from '../PlayButton/PlayButton';
/**
 * Bronson HeroTeaser component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-hero-teaser.html
 * @constructor
 */
export function HeroTeaser({ auto, bar, bottomCenter, bottomLeft, bottomRight, box, buttonIsHiddenOnLargeScreen, buttonProps, buttonText, centerLeft, centerRight, children, className, imgProps, inverted, layer, layerWidth, noImage, onButtonClick, pretitle, shallow, sub, title, topCenter, topLeft, topRight, video, testId, ...otherProps }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
    /**
     * Handle custom {@link PlayButton} interactions.
     * @type {(function(): (*|undefined))|*}
     */
    const onPlayButtonClick = useCallback(() => {
        if (videoRef?.current) {
            return isPlaying ? videoRef?.current?.pause() : videoRef?.current?.play();
        }
        return () => { };
    }, [isPlaying, videoRef]);
    /**
     * Handle `onPlay`, `onStop` and `onPause` events on the {@link Video} instance.
     * We cannot pass `setIsPlaying` directly into the video handlers as that
     * resolves in re-renders as the {@link Video} component does not memoize the invocations.
     * @type {(function(*): void)|*}
     */
    const onVideoToggle = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (plyr) => {
        setIsPlaying(!plyr?.media?.paused);
    }, [setIsPlaying]);
    const divClassNameList = classNames('c-hero-teaser', {
        // Main class modifier convenience prop extension.
        'c-hero-teaser--top-left': topLeft,
        'c-hero-teaser--top-center': topCenter,
        'c-hero-teaser--top-right': topRight,
        'c-hero-teaser--center-left': centerLeft,
        'c-hero-teaser--center-right': centerRight,
        'c-hero-teaser--bottom-center': bottomCenter,
        'c-hero-teaser--bottom-left': bottomLeft,
        'c-hero-teaser--bottom-right': bottomRight,
        'c-hero-teaser--shallow': shallow,
        'c-hero-teaser--auto': auto,
        'c-hero-teaser--inverted': inverted,
        'c-hero-teaser--video': video && noImage,
        'c-hero-teaser--layer': layer, // Convenience prop from Bronson variants.
    }, className).trim();
    function renderHeroTeaserPretitle() {
        if (pretitle) {
            return (React.createElement("div", { className: "c-hero-teaser__pretitle" },
                React.createElement("p", null, pretitle)));
        }
        return null;
    }
    function renderHeroTeaserSub() {
        if (sub) {
            return (React.createElement("div", { className: "c-hero-teaser__subtitle" },
                React.createElement("p", null, sub)));
        }
        return null;
    }
    function renderHeroTeaserText() {
        if (children) {
            return (React.createElement("div", { className: "c-hero-teaser__text" },
                React.createElement("p", null, children)));
        }
        return null;
    }
    const buttonWrapperClassNameList = classNames('c-hero-teaser__button', {
        'c-hero-teaser__button--hidden-on-large-screen': buttonIsHiddenOnLargeScreen,
    }).trim();
    function renderHeroTeaserHasButton() {
        if (buttonText) {
            return (React.createElement("div", { className: buttonWrapperClassNameList },
                React.createElement("button", { className: "c-btn", onClick: onButtonClick, type: "button", ...buttonProps },
                    React.createElement("span", { className: "c-btn__text" }, buttonText))));
        }
        return null;
    }
    const layerClassNameList = classNames('c-hero-teaser__layer').trim();
    function renderHeroTeaserHasLayer() {
        if (layer) {
            return (React.createElement("div", { className: layerClassNameList, "data-hero-teaser-layer-size": layerWidth ?? null }, layer));
        }
        return null;
    }
    function renderHeroTeaserHasBar() {
        if (bar) {
            return (React.createElement("div", { className: "c-hero-teaser__bar" },
                React.createElement("div", { className: "o-page-wrap" }, bar)));
        }
        return null;
    }
    function renderHeroTeaserImage() {
        /**
         * Handles fallback if {@link imgProps.alt} is not set.
         */
        const imgAttrs = {
            ...imgProps,
            ...{ alt: imgProps?.alt ?? getTextContent(title) ?? '' },
        };
        // eslint-disable-next-line jsx-a11y/alt-text
        return React.createElement("img", { className: "c-hero-teaser__image", ...imgAttrs });
    }
    function renderHeroTeaserTitle() {
        if (title) {
            const renderTitleChildren = (t) => {
                /**
                 * If the title was an array, map over it
                 * and wrap every item in `.c-hero-teaser__title-row`.
                 */
                if (Array.isArray(t)) {
                    return t.map((row, i) => (React.createElement("span", { className: "c-hero-teaser__title-row", key: `c-hero-teaser__title-row-${i}` }, row)));
                }
                /**
                 * If the title is a string or a valid React element,
                 * just return it as a single child.
                 */
                if (typeof t === 'string' || isValidElement(t)) {
                    return React.createElement("span", { className: "c-hero-teaser__title-row" }, t);
                }
                return null;
            };
            return (React.createElement("div", { className: "c-hero-teaser__title" },
                React.createElement("h1", null, renderTitleChildren(title))));
        }
        return null;
    }
    function renderHeroTeaserBoxOrTitle() {
        return box ? (React.createElement("div", { className: "c-hero-teaser__box" }, box)) : (
        // Hide the heading element if no content is present. Bronson template: 'hero-teaser-hide-header'.
        (pretitle || sub || title || children) && (React.createElement("header", { className: "c-hero-teaser__heading" },
            renderHeroTeaserPretitle(),
            renderHeroTeaserTitle(),
            renderHeroTeaserSub(),
            renderHeroTeaserText())));
    }
    function renderBackgroundVideo() {
        return video ? (React.createElement("div", { className: "c-hero-teaser__background-video" },
            React.createElement(Video, { ref: videoRef, ...video, onPlay: onVideoToggle, onPause: onVideoToggle, "aria-hidden": "true" }),
            React.createElement(PlayButton, { className: classNames('c-hero-teaser__background-video-control', {
                    'is-paused': !isPlaying,
                }), iconClassName: "c-hero-teaser__background-video-control-icon", label: "Start or stop the video", onClick: onPlayButtonClick }))) : (React.createElement("div", { className: "c-hero-teaser__image" }));
    }
    return (React.createElement("div", { ...otherProps, "data-testid": testId, className: divClassNameList },
        noImage || video ? renderBackgroundVideo() : renderHeroTeaserImage(),
        React.createElement("div", { className: "c-hero-teaser__content" },
            React.createElement("div", { className: "c-hero-teaser__content-inner" },
                renderHeroTeaserBoxOrTitle(),
                renderHeroTeaserHasButton(),
                renderHeroTeaserHasLayer())),
        renderHeroTeaserHasBar()));
}
