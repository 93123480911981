import React from 'react';
import classNames from 'classnames';
/**
 * Indicator Dot
 * @desc Visual indicator to communicate a status.
 * It includes default statuses like `default`, `info`, `success`, `warning`, `error` and a `unread` status, e.g. for messages.
 * @constructor
 */
export function IndicatorDot({ ariaLabelStatus, className, label, status, tooltip = null, testId }) {
    const indicatorDotClassNameList = classNames({
        'c-indicator-dot': true,
        [`c-indicator-dot--${status}`]: status,
    }, className).trim();
    return (React.createElement("span", { className: indicatorDotClassNameList, "data-testid": testId },
        tooltip ?? React.createElement("span", { className: "c-indicator-dot__dot", "aria-label": ariaLabelStatus }),
        label && React.createElement("span", { className: "c-indicator-dot__label" }, label)));
}
