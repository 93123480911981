import React from 'react';
import classNames from 'classnames';
export function ProductSearch({ children, sidebar, className, ...otherProps }) {
    const sectionClassName = classNames('c-product-search', {
        'c-product-search--has-sidebar': sidebar,
    }, className).trim();
    return (React.createElement("section", { className: sectionClassName, ...otherProps }, children));
}
export function ProductSearchLayoutHeader({ children }) {
    return React.createElement("header", { className: "c-product-search__header" }, children);
}
export function ProductSearchLayoutSidebar({ children }) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "c-product-search__sidebar" }, children)));
}
export function ProductSearchLayoutResults({ children }) {
    return React.createElement("div", { className: "c-product-search__results" }, children);
}
export function ProductSearchLayoutAside({ children }) {
    return React.createElement("div", { className: "c-product-search__aside" }, children);
}
export function ProductSearchLayoutFooter({ children }) {
    return React.createElement("div", { className: "c-product-search__footer" }, children);
}
ProductSearchLayoutHeader.displayName = 'ProductSearch.LayoutHeader';
ProductSearch.LayoutHeader = ProductSearchLayoutHeader;
ProductSearchLayoutSidebar.displayName = 'ProductSearch.LayoutSidebar';
ProductSearch.LayoutSidebar = ProductSearchLayoutSidebar;
ProductSearchLayoutResults.displayName = 'ProductSearch.LayoutResults';
ProductSearch.LayoutResults = ProductSearchLayoutResults;
ProductSearchLayoutAside.displayName = 'ProductSearch.LayoutAside';
ProductSearch.LayoutAside = ProductSearchLayoutAside;
ProductSearchLayoutFooter.displayName = 'ProductSearch.LayoutFooter';
ProductSearch.LayoutFooter = ProductSearchLayoutFooter;
