import React from 'react';
import classNames from 'classnames';
/**
 * Bronson Fieldset component.
 * The Fieldset object handles vertical spacing in form fields so the spacing
 * between individual form fields is consistent and can be controlled via CSS.
 * @see https://bronson.vwfs.tools/default/docs/objects/fieldset.html
 * @constructor
 */
export function Fieldset({ children, testId, ...otherProps }) {
    const classNameList = classNames('o-fieldset').trim();
    return (React.createElement("fieldset", { className: classNameList, "data-testid": testId, ...otherProps }, children));
}
/**
 * Bronson FieldsetRow component (nested).
 * @constructor
 */
export function FieldsetRow({ children, testId, ...otherProps }) {
    const classNameList = classNames('o-fieldset__row').trim();
    return (React.createElement("div", { className: classNameList, "data-testid": testId, ...otherProps }, children));
}
FieldsetRow.displayName = 'Fieldset.Row';
Fieldset.Row = FieldsetRow;
