import { useEffect } from 'react';
/**
 * Asynchronously observe resizes of a target reference element.
 * Please note that IE11 and other legacy browser do not implement the ResizeObserver API
 * and therefore need to polyfill the missing functionality.
 * @example
 *    const myTarget = useRef(null)
 *
 *    export function Example({}) {
 *      const myTarget = useRef(null);
 *      useResizeObserver({
 *        ref: myTarget,
 *        onResizeHandler: (entry) => {
 *          // Do your stuff on every observed resize here.
 *        },
 *      });
 *      return (
 *        <div ref={myTarget}>👋</div>
 *      );
 *    };
 */
function useResizeObserver({ ref, onResizeHandler }) {
    useEffect(() => {
        const { current } = ref;
        /**
         * If we have an {@link HTMLElement} to observe and a valid callback.
         */
        if (current && onResizeHandler) {
            const resizeObserver = new ResizeObserver((entries) => {
                onResizeHandler(entries);
            });
            resizeObserver.observe(current);
            return () => resizeObserver.unobserve(current);
        }
        return () => { };
    }, [onResizeHandler, ref]);
}
export default useResizeObserver;
