import React from 'react';
/*
 * Bronson ListInline component.
 */
export const ListInline = ({ children, testId, ...otherProps }) => {
    return (React.createElement("ul", { ...otherProps, "data-testid": testId, className: "o-list-inline" }, children /* Use 'ListInline.Item' component. */));
};
/*
 * Bronson Item component (nested).
 */
function Item({ children, ...otherProps }) {
    return React.createElement("li", { ...otherProps }, children);
}
Item.displayName = 'ListInline.Item';
ListInline.Item = Item;
