/* eslint-disable react-hooks/exhaustive-deps */
// TODO: check deps
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import nouislider from 'nouislider';
/**
 * Default options for the internal range slider.
 * @type {Partial<nouislider.Options>}
 */
export const noUiSliderDefaultOpts = {
    cssPrefix: 'c-custom-range-slider',
    cssClasses: {
        active: '--active',
        background: '--background',
        draggable: '--draggable',
        horizontal: '--horizontal',
        vertical: '--vertical',
        ltr: '--left-to-right',
        rtl: '--right-to-left',
        target: '__target',
        base: '__base',
        origin: '__origin',
        handle: '__thumb',
        handleLower: '__thumb--lower',
        handleUpper: '__thumb--upper',
        touchArea: '__touch-area',
        connects: '__connect-container',
        connect: '__connect',
        drag: '--drag',
        tap: '--tap',
        tooltip: '__tooltip-wrapper',
        pips: '__pips',
        pipsHorizontal: '__pips--horizontal',
        pipsVertical: '__pips--vertical',
        marker: '__marker',
        markerHorizontal: '__marker--horizontal',
        markerVertical: '__marker--vertical',
        markerNormal: '__marker--normal',
        markerLarge: '__marker--large',
        markerSub: '__marker--sub',
        textDirectionLtr: '--text-direction-ltr',
        textDirectionRtl: '--text-direction-rtl',
        value: '__value',
        valueHorizontal: '__value--horizontal',
        valueVertical: '__value--vertical',
        valueNormal: '__value--normal',
        valueLarge: '__value--large',
        valueSub: '__value--sub',
    },
};
/**
 * Checks whether a number is in range of a min and max range.
 * If {@link max} is not set the upper-bound of the range will be defined by {@link min}.
 * The match is including the min/max boundary values.
 *
 * @param {number} num - The number to check.
 * @param {number} min - The lower-bound of the range or the upper-bound if {@link max} is not defined.
 * @param {number|null|undefined} [max] - Optional upper-bound of the range.
 * @returns {boolean} - Whether {@link num} in inside the range.
 * @example
 *      inRange(1, 0, 10) => true
 *      inRange(1, 10) => true
 *      inRange(100, 10, 99) => false
 *      inRange(100, 10) => false
 *      inRange(10, 10) => true
 */
export const inRange = (num, min, max) => {
    return max ? num >= min && num <= max : num <= min;
};
export function CustomRangeSlider({ className, connect, disabled, noUiSliderOpts, onChange, onSet, pips, range, sliderInstanceRef, step, testId, tooltipPosition, tooltips, value, ...otherProps }) {
    const sliderRef = useRef(null);
    const sliderInstance = useRef(null);
    const noUiSliderConfig = { ...noUiSliderDefaultOpts, ...noUiSliderOpts };
    useEffect(() => {
        sliderInit();
        return () => sliderCleanup();
    }, []);
    const internalValue = React.useRef(null);
    internalValue.current = value;
    useEffect(() => {
        const currentValue = sliderInstance?.current?.get();
        const didValueChange = Array.isArray(value) && Array.isArray(currentValue)
            ? value.length === currentValue.length && value.every((i) => currentValue.includes(i))
            : currentValue === value;
        if (!didValueChange && range && value) {
            sliderInstance?.current?.updateOptions({
                range,
            }, false);
            sliderInstance?.current?.set(value);
        }
    }, [value, range]);
    const { min, max } = range;
    useEffect(() => {
        removePipsClickHandlers();
        cleanUpPipsUpdateOnChange();
        sliderInstance.current?.updateOptions({
            range,
            step,
            // @ts-ignore @TODO: Check if we can import Pips.Range type here.
            pips,
        }, true);
        if (pips) {
            addPipsClickHandlers();
            setUpPipsUpdateOnChange();
        }
    }, [min, max, step, pips?.mode, pips?.density, pips?.format?.to]);
    const componentClasses = classNames(
    // Prevent rendering the default class twice.
    noUiSliderConfig.cssPrefix === noUiSliderDefaultOpts.cssPrefix ? noUiSliderDefaultOpts.cssPrefix : '', className).trim();
    const componentDataAttributes = {
        'data-custom-range-slider-tooltip-position': tooltipPosition ?? (tooltips && !tooltipPosition ? 'top' : undefined),
        'data-custom-range-slider-pips': !!pips,
        'data-custom-range-slider-tooltips': tooltips ? true : null,
    };
    const tooltipClassName = `${noUiSliderConfig.cssPrefix}__tooltip`;
    const tooltipArrowClassName = `${noUiSliderConfig.cssPrefix}__arrow`;
    const markerSelector = `.${noUiSliderConfig.cssPrefix}__marker`;
    const markerValueSelector = `.${noUiSliderConfig.cssPrefix}__value`;
    const wrapTooltip = (customTooltips) => {
        /**
         * Define how to handle value formatting.
         * @param {boolean | PartialFormatter} v
         * @returns {((v: (string | number)) => string | number) | ((v: (string | number)) => string | number) | ((value: number) => (string | number))}
         */
        const tooltipFormatter = (v) => {
            if (typeof v === 'boolean') {
                return (v) => v;
            }
            if (typeof v === 'object' && typeof v.to === 'function') {
                return v.to;
            }
            return (v) => v;
        };
        /**
         * Change teh default markup of the tooltip to be wrapped in a `div`.
         * @param {boolean | PartialFormatter} v
         * @returns {{to: (v: number) => string}}
         */
        const formatTooltipMarkup = (v) => {
            const formatter = tooltipFormatter(v);
            return {
                to: (v) => `<div class="${tooltipClassName}">${formatter(v)}</div><div class="${tooltipArrowClassName}"> </div>`,
            };
        };
        if (Array.isArray(customTooltips)) {
            return customTooltips.map(formatTooltipMarkup);
        }
        else {
            return formatTooltipMarkup(customTooltips);
        }
    };
    function sliderInit() {
        if (sliderRef.current) {
            sliderInstance.current = nouislider.create(sliderRef.current, Object.assign(noUiSliderConfig, {
                connect: connect || [true, false],
                start: value,
                range,
                step,
                pips,
                tooltips: tooltips && wrapTooltip(tooltips),
            }));
        }
        if (pips) {
            addPipsClickHandlers();
            setUpPipsUpdateOnChange();
        }
        if (onChange) {
            getSliderValues('update', onChange);
        }
        if (onSet) {
            getSliderValues('set', onSet);
        }
        sliderInstanceRef?.(sliderInstance);
    }
    const getSliderValues = (event, callback) => {
        sliderInstance.current?.on(event, (values) => {
            /**
             * Compare current value against initial value.
             * 1. Check if `values` and `internalValue.current` is an array.
             * 2. For multiple handle controls compare array equality.
             * 3. For single handle control compare single value equality.
             */
            const hasChanged = Array.isArray(values) && Array.isArray(internalValue?.current)
                ? values.length === internalValue?.current?.length &&
                    !values.every((i) => internalValue?.current?.includes(parseFloat(`${i}`)))
                : // @ts-ignore @FIXME: Argument of type 'number | any[] | null | undefined' is not assignable to parameter of type 'string'. Type 'undefined' is not assignable to type 'string'.
                    parseFloat(values) !== parseFloat(internalValue?.current);
            if (hasChanged) {
                callback(values.length > 1 ? values : values[0]);
            }
        });
    };
    function sliderCleanup() {
        removePipsClickHandlers();
        sliderInstance.current?.destroy();
    }
    const updateSliderValue = (event) => {
        const eventTarget = event?.target;
        const currentValue = eventTarget?.hasAttribute('data-value')
            ? eventTarget?.getAttribute('data-value')
            : eventTarget?.nextElementSibling?.getAttribute('data-value');
        if (currentValue) {
            sliderInstance.current?.set(currentValue);
        }
    };
    function addPipsClickHandlers() {
        if (sliderRef.current) {
            sliderRef.current
                .querySelectorAll(`${markerSelector}, ${markerValueSelector}`)
                .forEach((node) => node.addEventListener('click', updateSliderValue));
        }
    }
    function removePipsClickHandlers() {
        if (sliderRef.current) {
            sliderRef.current
                .querySelectorAll(`${markerSelector}, ${markerValueSelector}`)
                .forEach((node) => node.removeEventListener('click', updateSliderValue));
        }
    }
    function setUpPipsUpdateOnChange() {
        if (sliderRef.current) {
            const markers = Array.from(sliderRef.current.querySelectorAll(markerSelector));
            sliderInstance.current?.on('update.pipsUpdate', (currentValue) => {
                /**
                 * Get the first and last (min and max) values:
                 * 1. Parse all values to a float.
                 * 2. Sort all floats ascending from min to max.
                 * 3. Filter out the first and last item (min/max), where `i === 0` is the first item,
                 *    and `i === arr.length - 1 && i !== 0` is the last one, excluding the first one.
                 */
                const [_min, _max] = currentValue
                    .map((val) => parseFloat(`${val}`))
                    .sort((a, b) => (a >= b ? a : b))
                    .filter((val, i, arr) => i === 0 || (i === arr.length - 1 && i !== 0));
                /**
                 * Iterate over the marker and set the related CSS classes.
                 */
                markers.forEach((marker) => {
                    const markerAttr = marker?.nextElementSibling?.getAttribute('data-value') ?? '';
                    const markerValue = parseFloat(markerAttr);
                    marker?.classList.remove('in-range', 'is-active');
                    if (inRange(markerValue, _min, _max)) {
                        marker?.classList.add(_min === markerValue ? 'is-active' : 'in-range');
                    }
                });
            });
        }
    }
    function cleanUpPipsUpdateOnChange() {
        sliderInstance.current?.off('update.pipsUpdate');
    }
    return (React.createElement("div", { "data-testid": testId, ref: sliderRef, className: componentClasses, "aria-disabled": disabled, 
        // @ts-ignore @TODO: Remove once React supports inerts, @see BRON-11871.
        inert: disabled ? '' : null, ...componentDataAttributes, ...otherProps }));
}
