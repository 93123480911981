import React from 'react';
import classNames from 'classnames';
/**
 * Bronson LicensePlate component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-license-plate.html
 * @constructor
 */
export function LicensePlate({ className, country, countryCode, euStars, horizontalStripEu, horizontalStripJp, jp, jpPrefectureCode, jpRegistrationPrefix, jpVehicleCode, nl, registrationNumber, size, testId, ...otherProps }) {
    const articleClassNameList = classNames('c-license-plate', {
        [`c-license-plate--${size}`]: size,
        'c-license-plate--nl': nl,
        'c-license-plate--jp': jp, // Convenience prop from Bronson variants.
    }, className).trim();
    function renderIfLicensePlateEuStars() {
        if (euStars) {
            return React.createElement("div", { className: "c-license-plate__eu-stars" });
        }
        return null;
    }
    function renderIfLicensePlateHorizontalStripEu() {
        if (horizontalStripEu) {
            return (React.createElement("div", { className: "c-license-plate__horizontal-strip c-license-plate__horizontal-strip--eu" },
                renderIfLicensePlateEuStars(),
                React.createElement("abbr", { className: "c-license-plate__country-code", title: country }, countryCode)));
        }
        return null;
    }
    function renderIfLicensePlateHorizontalStripJp() {
        if (horizontalStripJp) {
            return (React.createElement("div", { className: "c-license-plate__horizontal-strip c-license-plate__horizontal-strip--jp" }, jpRegistrationPrefix));
        }
        return null;
    }
    return (React.createElement("article", { ...otherProps, "data-testid": testId, className: articleClassNameList, "aria-label": "License Plate" },
        renderIfLicensePlateHorizontalStripEu(),
        renderIfLicensePlateHorizontalStripJp(),
        React.createElement("div", { className: "c-license-plate__registration-number", "aria-label": "Registration Number" }, jpRegistrationPrefix ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "c-license-plate__registration-top-bar" }, `${jpPrefectureCode} ${jpVehicleCode}`),
            React.createElement("span", { className: "c-license-plate__registration-bottom-bar" }, registrationNumber))) : (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "c-license-plate__registration-bottom-bar" }, registrationNumber))))));
}
