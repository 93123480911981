import React from 'react';
import classNames from 'classnames';
import { FormContext } from './FormContext';
/**
 * The Form component is used to collect, validate, and submit the user input.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-form.html
 * @constructor
 */
export function Form({ children, className, floatingLabel, testId, ...otherProps }) {
    const contextValue = {
        floatingLabel,
    };
    const classNameList = classNames({
        'is-js-on': floatingLabel, // Used in Bronson CSS.
    }, className).trim();
    return (React.createElement("form", { className: classNameList, "data-testid": testId, ...otherProps },
        React.createElement(FormContext.Provider, { value: contextValue }, children)));
}
