import React from 'react';
import classNames from 'classnames';
/*
 * Bronson ListUi component.
 */
export function ListUi({ children, className, ordered, testId, ...otherProps }) {
    // tag containing a variable or condition
    const CustomTagListUiElement = ordered ? 'ol' : 'ul';
    const ClassNameList = classNames('c-list-ui', {
        // main class modifier convenience prop extension
        'c-list-ui--ordered ': ordered, // Convenience prop from Bronson variants.
    }, className).trim();
    return (React.createElement(CustomTagListUiElement, { ...otherProps, "data-testid": testId, className: ClassNameList }, children));
}
/*
 * Bronson ListUiItem component (nested).
 */
function ListUiItem({ children, ...otherProps }) {
    return (React.createElement("li", { ...otherProps, className: "c-list-ui__item" }, children));
}
ListUiItem.displayName = 'ListUi.Item';
ListUi.Item = ListUiItem;
